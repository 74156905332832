.filters-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 40px;
  border-bottom: solid 1px;
  border-bottom-color: rgba(41, 41, 41, 0.12);
  margin-top: 13px;
}

.filter-page-name {
  font-family: 'Public Sans';
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 32px;
  width: 20%;
}

.filters-actions {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 90%;
  height: 100%;

  .filter-action-container {
    width: 63%;
    justify-content: flex-end;
    display: flex;
    flex-direction: row;

    .filters-action {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 16px;

      .filter-action-title {
        font-family: 'Public Sans';
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;
        display: flex;
        align-items: center;
        color: #292929;
      }

      .filter-icons {
        font-size: 25px;
      }

      .filter-icons:hover {
        color: var(--primary-color);
      }
    }
  }
}

.filter-drop-caret {
  display: contents;
}