.img-selector{
    height: 320px;
    width: 440px;
    background: #FFFFFF;
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-content: center;
    text-align: center;
    border: 2px dashed rgba(41, 41, 41, 0.12);
    box-sizing: border-box;
    border-radius: 2px;
    .img-selector-input{
        opacity: 0;
    }
    .btn-rounded{
        border-radius: 50px;
    }
}

.clone-experience{
    margin-bottom: 10px;
}
