.file-container{
    position: relative;
}

.new-logo-preview-form{
    width: 496px;
    height: 252px;
    background: #fff;
    /* Transparency / Black 12 */

    border: 1px dashed rgba(41, 41, 41, 0.12);
    box-sizing: border-box;
    border-radius: 2px;
    display: flex;
    vertical-align: middle;
    &.file-selected{
        background: rgba(41, 41, 41, 0.04);
    }
    .image-input{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .label-input-image{
            display: flex;
            flex-direction: row;
            justify-content: center;
            width: 100%;
            font-family: 'Public Sans';
            font-style: normal;
            font-weight: normal;
            font-size: 1rem;
            line-height: 16px;
            /* identical to box height, or 100% */

            display: flex;
            align-items: center;
            text-align: center;
            

            /* Neutrals / Black */

            color: #292929;
            .scan-icon{
                font-size: 1rem;
                color: var(--primary-color);
            }
        }
    }
    .new-logo-preview-image{
        margin: auto;
        max-height: 120px;
        max-width: 175px;
    }
}

.preview-file{
    flex-direction: row;
}


.btn{
    cursor: pointer;
    width: 20px;
    height: 20px;
    &.btn__big{
        width: 200px;
        height: 300px;
    }
     &.btn__small{
        width: 10px;
        height: 5px;
    }
    &.btn__orange{
        color: red;
    }
}

.new-logo-preview{ 
    width: 100%;
    height: 120px;
    background: #fff;
    /* Transparency / Black 12 */

    border: 1px dashed rgba(41, 41, 41, 0.12);
    box-sizing: border-box;
    border-radius: 2px;
    display: flex;
    vertical-align: middle;
    
    &.file-selected{
        background: rgba(41, 41, 41, 0.04);
    }
    .image-input{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .label-input-image{
            display: flex;
            flex-direction: row;
            justify-content: center;
            width: 100%;
            font-family: 'Public Sans';
            font-style: normal;
            font-weight: normal;
            font-size: 0.8rem;
            line-height: 16px;
            /* identical to box height, or 100% */

            display: flex;
            align-items: center;
            text-align: center;

            /* Neutrals / Black */

            color: #292929;
            .scan-icon{
                font-size: 0.6rem;
                color: var(--primary-color);
            }
        }
    }
    .new-logo-preview-image{
        margin: auto;
        max-height: 120px;
        max-width: 180px;
    }
}

.image-input{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .label-input-image{
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: 100%;
        font-family: 'Public Sans';
        font-style: normal;
        font-weight: normal;
        font-size: 0.8rem;
        line-height: 16px;
        padding-top: 5px;
        /* identical to box height, or 100% */

        display: flex;
        align-items: center;
        text-align: center;
        margin-bottom: 10px;

        /* Neutrals / Black */

        color: #292929;
        .scan-icon{
            font-size: 0.8rem;
            color: var(--primary-color);
        }
    }
}
.new-logo-preview-image{
    margin: auto;
    max-height: 120px;
    max-width: 180px;
}
.quit-image{
    margin-top: 15px;
    width: 100%;
}
.preview-logo-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 75%;
}
