select {
    padding: 2%;
    width: 100%;
}
.form .form-text{
    width: 100%;
    margin-top: 5px;
}

.form .form-group .form-label{
    font-weight: bold;
}
.text-center{
    align-items: center;
}

.btn-success{
    width: 10%;
}
.btn-rounded{
    border-radius: 50px;
}

.form{
    margin: 2%;
}


.section-previsualicer{
    display: flex;
    flex-direction: column;
    width: 50%;
}
.form-previsualizer{
    display: flex;
    width: 90% !important;
}
.select-overlays{
    width: 40% ;
    margin: 10px !important;
}
.previsualizer-container{
    position: fixed;
    z-index: 50;
    right: 10px;
    width: 50%;
    height: 100%;
}
.item-overlay{
    width: 40%;
    margin: 10px !important;
    margin-left: 0px !important;
}
.bg-url{
    width: 50%;
}
.item-overlay-header{
    margin: 15px !important;
    justify-content: center !important;
}