.loading-overlay{
  position: fixed;
  z-index: 5000; 
  background-color: rgba(41, 41, 41, 0.24);
  left: 0;
  top: 0;
}

.loading-overlay-full{
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loading-overlay-projects{
  margin-top: 180px;
  margin-left: 320px;
  width: calc(100% - 320px);
  height: calc(100% - 180px);
  display: flex;
  justify-content: center;
  align-items: center;  
}

.loading-container{
  position: absolute;
  top: calc(50% - 25px);
  left: calc(50% - 150px);
  display: flex;
  
  width: 300px;
  height:50px;
  background-color:#fff;
  padding:5px;
  /* Illustration / Hard shadow */

  box-shadow: 12px 12px 24px rgba(41, 41, 41, 0.24);
  border-radius: 16px;
  display: flex;
  align-items: center;
  flex-direction: row;
  
    .loading-content{
      margin: 0 5px 0 5px;
      line-height:50px;
      background: #3487F0;
      box-shadow: 12px 12px 24px rgba(41, 41, 41, 0.24);
      border-radius: 4px;
      animation: loading 5s ease-in-out infinite;
    }
  }



@keyframes loading{
  0%{
    /*empieza*/
    width: 2px;
    height: 40px;
    
    
  }
  10%{
    /*crece hasta total - n*/
    width: 120px;
  }
  15%{
    /*decrese hasta (total - n) - p genererando efecto de rebote*/
    width: 80px;
  }
  25%{
    /*crece hasta total*/
    margin-left:5px;
    width: 100%;
    height: 42px;
  }
  35%{
    /*retrae el margen izquierdo hasta dejar un pxel*/
    margin-left:96%;
    width: 2px;
    height: 42px;
  }
  45%{
    /* disminuye el alto de la barra en el lado derecho*/
    margin-left:96%;
    align-self: flex-end;
    
    width: 2px;
    height: 2px;
  }
  60%{
    /*despliega la barra nuevamente al lado izquierdo pero no width y height 1*/
   margin-left:5px;
    align-self: flex-end;
    height: 2px;
    width: 100%;
  }
  
  80%{
     /*mantiene el w y el h en 1 */
    height: 2px;
    width: 2px;
  }
  100%{
    /*hace crecer la barra ahora del lado izquierdo*/
    height: 40px;
    width: 2px;
    align-self: flex-end;
  }
  
}



