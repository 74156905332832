.overviewTile {
  padding-top: 14px;
  border-radius: 8px;
  box-shadow: 0px -1px 2px rgba(41, 41, 41, 0.24), 0px 2px 4px rgba(41, 41, 41, 0.12);
  background: #FFFFFF;
  height: 140px;
}

.blue-background {
  background: #3487F0;
  border-radius: 0px 0px 8px 8px;
  height: 16px;
  margin-top: 13px
}
.yellow-background {
  background: #F0B40F;
  border-radius: 0px 0px 8px 8px;
  height: 16px;
  margin-top: 13px
}
.purple-background {
  background: #ff4672;
  border-radius: 0px 0px 8px 8px;
  height: 16px;
  margin-top: 13px
}

.overviewTileTitle {
  font-family: Public Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 32px;
  text-align: center;
  letter-spacing: -0.0125em;
  color: rgba(41, 41, 41, 0.72);
}

.overviewTileValue {
  text-align: center;
  font-family: Public Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 52px;
  line-height: 56px;
  /* identical to box height, or 108% */
  /* Neutrals / Black */
  padding-top: 10px;
  color: #292929;
}