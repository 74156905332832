.container-fluid {
  padding-right: 8px;
  padding-left: 8px;

  .main-row {
    margin-left: 0;
    margin-right: 0;

  }
}

.login-box {
  .login-sidebar {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    padding-top: 10px;
    height: 100vh;

    .logo {
      padding-top: 30px;
      width: 60px;
    }
  }
}

.main-content {
  display: flex;
  justify-content: center;
  text-align: center;
  padding: 100px;

  .content-box {
    width: 100%;
    flex-direction: column;
  }
}

.right-login-sidebar {
  padding-right: 0;
  width: 100%;
  height: 92.3vh;

  .image-login {
    width: 100%;
    height: 100%;
    background-image: url('/img-login.svg');
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.containerS {
  overflow: hidden;
  background-size: cover;
  background-position: center;
  background-image: url('/tool.svg');
}

.hoverTitle {
  color: #3487F0 !important;
}


.left-login-sidebar {
  padding-left: 0;
  width: 100%;
  height: 92.3vh;

  .image-login {
    width: 100%;
    height: 100%;
    background-image: url('/sign-up-illustration.svg');
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.full {
  width: 100%;
}

.flex-row {
  display: contents;
  flex-direction: row;
}

.colorRedLabel {
  span {
    color: #EF0000 !important;
  }
}
