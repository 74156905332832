.mdc-dialog{
    z-index: 50;
}
.dialog-full.mdc-dialog{
    .mdc-dialog__container{
        .mdc-dialog__surface{
            height: 100%;
            width: 100vw;
            height: 100vh;
            max-height: 100vh !important;
            max-width: 100vw !important;
        }
    }
}


.mdc-dialog__title{
    margin-top: 15px;
    display: flex;
    flex-direction:row;
    
        &.only-close{
            justify-content: flex-end;
            align-items: flex-end;
        }
        &.space-bet{
            justify-content: space-between;
            align-items: flex-end;
        }
}
.mdc-dialog__title:before{
    display: none;
}
.new-experience-title-container{
    display: flex;
    justify-content: center;
    .new-experience-title{
        font-family: 'Public Sans';
        font-style: normal;
        font-weight: bold;
        font-size: 30px;
        line-height: 40px;
        /* identical to box height, or 133% */
    
        display: flex;
        align-items: center;
        text-align: center;
    
        /* Neutrals / Black */
    
        color: #292929;
    }

}

.new-experience-type:hover p{
    color: #3487F0;
};

.new-experience-type.disabled:hover p{
    color: inherit;
};

.noHoverSpan{
    color: #7b7a7ab8 !important;
}


.noHoverSpan:hover{
    color: #7b7a7ab8 !important
}

.noHoverTitleGray{
    color: gray !important;
}

.new-experience-type-container-gray{
    display: flex;
    margin: auto;
    height: 80%;
    justify-content: center;
    .new-experience-type{
        cursor: pointer;
        width: 400px;
        border-radius: 16px;
        height: 600px;
        margin-left: 30px;
        margin-right: 45px;
        margin-top: 20px;
        padding: 13px;
        position: relative;
        transition: all 1s ease-in-out;
        .new-experience-button{
            position: absolute;
            bottom: 50px;
        }
        .new-experience-type-header{
            height: 60%;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            .placeholder{
                background: rgba(41, 41, 41, 0.04);
            }
        }
        .new-experience-type-title{
            display: flex;
            justify-content: center;
            font-family: 'Public Sans';
            font-style: normal;
            font-weight: bold;
            font-size: 22px;
            line-height: 24px;
            /* identical to box height, or 109% */

            display: flex;
            align-items: center;
            text-align: center;

            /* Neutrals / Black */

            color: #292929;
        }
        .new-experience-type-text{
            font-family: 'Public Sans';
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 24px;
            /* or 150% */

            display: flex;
            align-items: center;
            text-align: center;
            letter-spacing: -0.0125em;
            justify-content: center;

            /* Transparency / Black 72 */

            color: rgba(41, 41, 41, 0.72);
            margin-top: 10px;
            margin-bottom: 10px;
        }
        .new-experience-type-button{
            display: flex;
            justify-content: center;
        }
    }

    .new-experience-type:hover{
        box-shadow: 0px 12px 72px rgba(41, 41, 41, 0.12);
        transition: box-shadow 0.75s;
    }

    .new-experience-form{
        width: 60%;
        margin: auto;
    }
}

.new-experience-type-container{
    display: flex;
    margin: auto;
    height: auto;
    justify-content: center;
    .new-experience-type{
        cursor: pointer;
        width: 320px;
        border-radius: 12px;
        height: 544px;
        margin-left: 43px;
        margin-right: 45px;
        margin-top: 20px;
        position: relative;
        transition: all 1s ease-in-out;
        .new-experience-button{
            position: absolute;
            bottom: 50px;
        }
        .new-experience-type-header{
            flex-direction: row;
            justify-content: center;
            align-items: center;
            .placeholder{
                background: #ffffff
            }
        }
        .new-experience-type-title{
            margin-bottom: 8px;
            width: 100%;
            font-family: 'Public Sans';
            font-style: normal;
            font-weight: bold;
            font-size: 22px;
            line-height: 24px;
            color: #292929;
            display: flex;
            justify-content: center;
        }
        .new-experience-type-text{
            font-family: 'Public Sans';
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 24px;
            /* or 150% */

            display: flex;
            align-items: center;
            text-align: center;
            letter-spacing: -0.0125em;
            justify-content: center;

            /* Transparency / Black 72 */

            color: rgba(41, 41, 41, 0.72);
            margin: 8px 32px 32px;
        }
        .new-experience-type-button{
            display: flex;
            justify-content: center;
        }
    }

    .new-experience-type:hover{
        box-shadow: 0px 12px 72px rgba(41, 41, 41, 0.12);
        transition: box-shadow 0.75s;
    }

    .new-experience-type.disabled {
        cursor: default;
        -webkit-user-select: none; /* Safari */
        -ms-user-select: none; /* IE 10 and IE 11 */
        user-select: none; /* Standard syntax */

        &:hover {
            box-shadow: none;
        }

    }


    .new-experience-form{
        width: 60%;
        margin: auto;
    }
}
.new-experience-button{
   border-radius: 50px;
   height: 50px;
    .mdc-button__label{
        font-family: 'Space Mono';
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
    }
}
.new-experience-button:hover{
    background: var(--primary-color);
    color: rgb(255, 255, 255);
}


.new-experience-button-noH:hover{
    color: black !important;
}

.new-experience-button-noH{
    color: black !important;
}

.newExpButton-notValid{
    background: gray !important;
    color: white !important;
    border: solid !important;
}


.newExpButton-valid{
    background-color: white!important;
    color: var(--primary-color)!important;
}

.newExpButton-valid:hover{
    background: var(--primary-color) !important;
    color: white !important;
}


.new-experience-back{
    cursor: pointer;
    position: absolute;
    left: 15px;
}

.experience-button{
    margin: auto;
    width: 300px;
}
.new-experience-file-input{
   width: 100%;
    .mdc-text-field__icon{
        position: relative !important;
        left: 12px !important;
        bottom: 4px !important;
        right: 0 !important;
        display: flex;
        align-items: center;
    }
}
.new-experience-file-input-icon{
    font-size: 21px;
}
.file-input-value{
    position: absolute;
    display: flex;
    align-items: center;
    top: 25%;
    left: 59px;
}
.file-container{
    position: relative;
}

.new-experience-preview-form{
    width: 496px;
    height: 252px;
    background: #fff;
    /* Transparency / Black 12 */
    margin: 10px;
    border: 1px dashed rgba(41, 41, 41, 0.12);
    box-sizing: border-box;
    border-radius: 2px;
    display: flex;
    vertical-align: middle;
    margin-bottom: 50px;
    &.file-selected{
        background: rgba(41, 41, 41, 0.04);
    }
    .image-input{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .label-input-image{
            display: flex;
            flex-direction: row;
            justify-content: center;
            width: 100%;
            font-family: 'Public Sans';
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            line-height: 16px;
            /* identical to box height, or 100% */

            display: flex;
            align-items: center;
            text-align: center;
            margin-bottom: 15px;

            /* Neutrals / Black */

            color: #292929;
            .scan-icon{
                font-size: 26px;
                color: var(--primary-color);
            }
        }
        .label-secondary{
            display: flex;
            flex-direction: row;
            justify-content: center;
            width: 100%;
            font-family: 'Public Sans';
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 16px;
            color: rgba(41, 41, 41, 0.48);
            margin-bottom: 75px;
        }
    }
    .new-experience-preview-image{
        margin: auto;
        max-height: 300px;
        max-width: 492px;
    }
}


.new-experience-preview{
    width: 70%;
    height: 400px;
    background: #fff;
    /* Transparency / Black 12 */

    border: 1px dashed rgba(41, 41, 41, 0.12);
    box-sizing: border-box;
    border-radius: 2px;
    display: flex;
    vertical-align: middle;
    margin-bottom: 50px;
    &.file-selected{
        background: rgba(41, 41, 41, 0.04);
    }
    .image-input{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .label-input-image{
            display: flex;
            flex-direction: row;
            justify-content: center;
            width: 100%;
            font-family: 'Public Sans';
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            line-height: 16px;
            /* identical to box height, or 100% */

            display: flex;
            align-items: center;
            text-align: center;
            margin-bottom: 15px;

            /* Neutrals / Black */

            color: #292929;
            .scan-icon{
                font-size: 26px;
                color: var(--primary-color);
            }
        }
        .label-secondary{
            display: flex;
            flex-direction: row;
            justify-content: center;
            width: 100%;
            font-family: 'Public Sans';
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 16px;
            color: rgba(41, 41, 41, 0.48);
            margin-bottom: 15px;
        }
    }
    .new-experience-preview-image{
        margin: auto;
        max-height: 300px;
        max-width: 492px;
    }
}

.imageMargin {
    margin-bottom: 35px;
    padding: 5px;
}

.new-experience-preview-text{
    width: 30%;
    height: 400px;
    margin-left: 40px;
    font-family: 'Public Sans';
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    
    .title{
        color: rgba(41, 41, 41, 0.72);
    }
    .description{
        color: rgba(41, 41, 41, 0.4);
    }
    .footer{
        color: rgba(41, 41, 41, 0.4);
        .link{
            color: var(--primary-color);
            text-decoration: underline;
        }
    }
}
.preview-file{
    flex-direction: row;
}


.btn{
    cursor: pointer;
    width: 20px;
    height: 20px;
    &.btn__big{
        width: 200px;
        height: 300px;
    }
     &.btn__small{
        width: 10px;
        height: 5px;
    }
    &.btn__orange{
        color: red;
    }
    .btn--price{

    }
    .btn--text{

    }
}

.mdc-card__action-icons {
    justify-content:center;
}

.clone-demo-experience-container {
    height: 200px;
    width: 400px;
    
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between;
}