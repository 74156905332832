.drop-menu-container {
  background: #292929;
  color: #FFFFFF;
  max-height: 1000px !important;
}

.drop-menu-item {
  height: 32px;
  color: #FFFFFF;

  &.selected {
    color: #FFFFFF;

  }

  .selected-check {
    font-size: 18px;
    color: #FFFFFF
  }
}

.drop-menu-item:hover {
  background: var(--primary-color);
}


.drop-filter-button {
  font-family: 'Public Sans';
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
}

.menu-item-container {
  width: 10%;
  padding-right: 15px;
}

.menu-text-container {
  display: flex;
  flex-direction: row;
  font-family: Public Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  align-items: center;
  padding: 16px;
  color: white;
  height: 32px;
}

.drop-menu-container {
  z-index: 20;
  width: 15rem;
}

.mdc-button__label {
  text-transform: none !important;
}

.drop-menu-container {
  z-index: 20;
  width: 15rem;
}

.offlineInputControl .rs-form-control-wrapper{
  width: 100%;
}