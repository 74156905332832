$primaryColor: #3487f0;
$secondaryColor: #ff4672;
$grayColor: #808080;

@mixin base ($prim, $sec:white, $fsize:16px, $fspacing:1px, $fluid:'false', $raised:'false', $disable:'false', $shadow:'false'){
    background-color: $prim;
    color: $sec;
    border: 1px solid $prim;
    font-size: $fsize;
    letter-spacing: $fspacing;
    @if $fluid=='true'{
        width: 100%;
    }@else{
        width: max-content;
    }
    padding: 12px 15px;
    border-radius: 200px;
    font-family: Space Mono;
    font-style: normal;
    font-weight: 400;
    font-size: $fsize;
    line-height: 24px;
    transition: all .1s ease-out;

    /* flex */
    display: flex;
    align-items: center;
    justify-content: center;
    @if ($shadow=='true'){
        border: none!important;
        box-shadow: 0 3px 3px -3px rgba(0, 0, 0 , 5%)!important;
    }@else{
        border: 1px solid $prim!important;
    }
    &:hover{
        @if ($disable!='true'){
            @if ($raised=='true'){
                border: 1px solid $sec;
                color: $sec;
                background-color: rgba($prim, .8);
            }@else{
                border: 1px solid $prim;
                color: $prim;
                background-color: $sec;
            }
        }
    }
}


button.sausage-button{
    @include base($primaryColor, white);
    &.secondary{
        @include base($secondaryColor, white);
    }
    &.invert{
        @include base(white, $primaryColor);
    }
    &.outlined{
        @include base(white, $primaryColor);
        border: 1px solid $primaryColor!important;
    }
    &.disabled{
        transition: none;
        @include base($grayColor,white,16px,0,'false','true','true');
    }
    
    // SIZES

    &.large{
        height: 56px;
    }
    &.medium{
        height: 40px;
    }
    &.small{
        height: 32px;
    }
    &.fluid{
        width: 100%!important;
        transition: all .1s ease-out;
        box-shadow: 0 0 30px silver;
        @include base($primaryColor,white,16px,0,'true','true','false','true');
        &:hover{
            box-shadow: 0 2px 8px -1px gray!important;
        }
    }
}
/* Loading icon */

.lds-ring {
    position: relative;
    top: -8px;
    width: 16px;
    height: 16px;
  }
  .lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 26px;
    height: 26px;
    margin: 2px;
    border: 2px solid #fff;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #fff transparent transparent transparent;
  }
  .lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
  }
  .lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
  }
  .lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  