.confirmation-container.with-header {
  padding-top: 100px;
}

.confirmation-container .new{
  position: absolute;
  display: flex;
  justify-content: center;
  left: -145px;
  top: -61px;
  z-index: 11;
}
.confirmation-container .old{
  position: absolute;
  display: flex;
  justify-content: center;
  z-index: 10;
}
.confirmation-container .login-button {
  border-radius: 200px;
  width: 320px;
  height: 56px;
  margin-top: 20px;
  border-radius: 200px;
  height: 55px;
  font-family: 'Space Mono';
  font-size: 17px;
  font-weight: 400;
  text-transform: none;
  letter-spacing: -0.05em;
}