.floating-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  width: 48px;
  height: 48px;
  background-color: #000;
  border-radius: 50%;
  box-shadow: 0px 4px 12px rgba(41, 41, 41, 0.70);
  position: fixed;
  right: 30px;
  bottom: 30px;
}

.colorButton .rs-dropdown-menu {
  background: #292929;
}

.colorButton .rs-dropdown-menu .rs-dropdown-item-content {
  display: flex;
  flex-direction: row;
  font-family: Public Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  align-items: center;
  padding: 16px;
  color: white;
  height: 32px;
}

.colorButton .rs-dropdown-open.rs-dropdown-placement-top-start > .rs-dropdown-menu, .rs-dropdown-open.rs-dropdown-placement-top-end > .rs-dropdown-menu {
  bottom: 122% !important;
}

.colorButton .rs-btn-primary {
  background-color: black;
}

.colorButton .rs-btn-primary:not(.rs-btn-disabled):hover {
  background-color: black;
}

.colorButton .rs-btn-primary:active:hover, .rs-btn-primary.rs-btn-active:hover, .rs-open > .rs-dropdown-toggle.rs-btn-primary:hover, .rs-btn-primary:active:focus, .rs-btn-primary.rs-btn-active:focus, .rs-open > .rs-dropdown-toggle.rs-btn-primary:focus, .rs-btn-primary:active.focus, .rs-btn-primary.rs-btn-active.focus, .rs-open > .rs-dropdown-toggle.rs-btn-primary.focus {
  background-color: black;
}

.colorButton .rs-btn-primary:active, .rs-btn-primary.rs-btn-active, .rs-open > .rs-dropdown-toggle.rs-btn-primary {
  background-color: black;
}

.colorButton .rs-btn-primary:focus, .rs-btn-primary.rs-btn-focus {
  background-color: black;
}

.colorButton .rs-dropdown-menu > .rs-dropdown-item > .rs-dropdown-item-content:hover, .rs-dropdown-menu > .rs-dropdown-item > .rs-dropdown-item-content:focus {
  background-color: var(--primary-color);
  color: #FFFFFF;
}