.freePlanSubsP {
  font-family: Public Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 40px;
  line-height: 48px;
}

.yourPlanContainer {
  height: 150px !important;
}

.profile-pro-button{
  margin-left: 5px;
  color: red;
  cursor:pointer;
}
.profile-pro-button:hover{
  opacity: .6;
}

.iconCloseSubs {
  margin-right: 16px;
  margin-top: 16px;
  color: #3487F0;
  cursor: pointer;
  font-size: 16px !important;
}

.switchCoA {
  background: rgba(41, 41, 41, 0.04);
  border-radius: 200px;
  width: 320px;
  height: 48px;
  display: flex;
  padding: 4px;
  cursor: pointer;
  font-family: Public Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
}

.selectedTextPurp {
  font-family: Public Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #ff4672;
}

.inputName{
  width: 100% !important;
}

.grayTextSwitch {
  font-family: Public Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: rgba(41, 41, 41, 0.48);
}

.noCleanIcon .rs-picker-toggle-clean {
  visibility: hidden;
}


.selectedBackgroundWhite {
  border-radius: 200px;
  background: #FFFFFF;
  box-shadow: 0px 4px 12px rgba(41, 41, 41, 0.08);
  transition: background-position;
}

.selectedBackgroundGray {
  border-radius: 200px;
  background: transparent;
  box-shadow: 0px 4px 12px rgba(41, 41, 41, 0.08);
}

.monthlyS {
  width: 152px;
  border-radius: 200px;
  padding: 8px 16px;
  text-align: center;
  margin-right: 24px;
  transition: background-color .3s;
}

.yearlyS {
  width: 152px;
  border-radius: 200px;
  padding: 8px 16px;
  text-align: center;
}

.spanSubscription {
  font-family: Public Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 32px;
}

.profile-card-container {
  margin-top: 64px;
  display: flex;
  flex-direction: column;
  width: 60%;

  .profile-element {
    margin: 12px 0 10px 0;
    display: flex;
    flex-direction: column;

    .profile-title {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 12px;
      background-color: rgba(41, 41, 41, 0.04);

      span {
        font-family: 'Public Sans';
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        line-height: 16px;
        align-self: center;
        color: rgba(41, 41, 41, 0.72);
      }

      .profile-title-edit {
        cursor: pointer;
        font-family: Space Mono;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 24px;
        color: var(--primary-color);
      }
    }

    .profile-item {
      display: flex;
      flex-direction: row;
      padding: 6px 10px 3px 10px;
      border-bottom: solid 1px;
      align-items: center;
      height: 64px;
      border-bottom-color: rgba(41, 41, 41, 0.12);

      .profile-item-label {
        display: flex;
        width: 50%;
        flex-direction: column;
        justify-content: flex-start;
        align-self: center;
        align-content: center;
        font-family: Public Sans;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 24px;
        /* identical to box height, or 171% */


        /* Neutrals / Black */

        color: #292929;
      }

      .profile-item-value {
        display: flex;
        width: 50%;
        justify-content: flex-end;
        font-family: Public Sans;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 24px;
        /* identical to box height, or 171% */


        /* Neutrals / Black */

        color: #292929;

        &.invoices {
          justify-content: space-between;

          a {
            font-family: Space Mono;
            font-style: normal;
            font-weight: normal;
            color: var(--primary-color);
          }
        }
      }
    }
  }
}

.titleProPassing {
  font-family: Public Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 40px;
  margin-bottom: 10px;
  /* identical to box height, or 133% */
  /* Neutrals / Black */

  color: #292929;
}