.homeBreadcrumbMargin {
  margin-top: 20px;
  font-family: Public Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 32px;
  color:#292929;
}

.homeBreadcrumbMargin.rs-breadcrumb {
  padding: 0 !important;
}

.homeBreadcrumbMargin a:hover, a:focus {
  color: #575757 !important;
}

.campaing-cards-container {
  flex-wrap: wrap;
  justify-content: flex-start;
  box-sizing: border-box;
  display: flex;
  flex: 0 1 auto;
  flex-direction: row;
}

.list {
  width: 100%;

  .list-container {
    display: flex;
    flex-direction: row;
  }
}

.list-header {
  cursor: pointer;
  display: flex;
  flex-direction: row;

  .header-actions {
    display: flex;
    flex-direction: row;
    margin-left: auto;

    .actions {
      display: flex;
      flex-direction: row;
      margin-left: auto;
      color: #292929;

      .value {
        font-family: Public Sans;
        font-style: normal;
        font-weight: normal;
        margin-right: 18px;
        font-size: 12px;
        color: #292929;
      }

      .quantity {
        font-family: 'Public Sans';
        font-style: normal;
        font-weight: normal;
        margin-right: 18px;
        font-size: 12px;
        color: #292929;
        width: 100px;
        padding: 0px 10px 0px 10px;
        text-align: center;
        border-left: solid;
        border-right: solid;
        border-left-width: thin;
        border-right-width: thin;
        border-color: rgba(41, 41, 41, 0.12);
      }
    }
  }
}

.rmwc-collapsible-list__children-inner {
  width: 100%;
  display: flex;
  flex-direction: row;
}


.rmwc-collapsible-list__children-inner {
  padding-top: 40px;
  padding-bottom: 40px;
}
