.sign-up-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.sign-up-container .mdc-text-field__input {
    font-family: 'Public Sans';
    font-weight: 400;
    color: #3487F0;
    font-size: 16px;
    line-height: 24px;
}

.sign-up-container .mdc-floating-label {
    font-family: 'Public Sans';
    font-weight: 400;
}

.sign-in-title {
    font-family: 'Space Mono';
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 32px;
}

.sign-up-container .sign-form {
    width: 360px;
}

.sign-form {
    display: flex;
    flex-direction: column;
}

.form-control {
    display: flex;
    flex-direction: column;
    margin: 30px 0 30px 0;
}

.inputGroupCoA {
    height: 40px;
    background: #FFFFFF;
    border-radius: 4px;
}

/* To vertical align input box
 TODO: unificar estilos y librerias */
.rs-picker-group{
    height: 100%;
}

.rs-picker-input{
    height: 100%!important;
    border-bottom-left-radius: 0!important;
    border-top-left-radius: 0!important;
}

a.rs-btn{
    height: 100%!important;
    display: flex!important;
    align-items: center!important;
    vertical-align: middle;
}

.rs-picker-toggle-caret{
    transform: translateY(2px);
}

.inputGroupCoA .rs-btn-default {
    background: transparent;
}

.inputCoAError {
    border: solid 2px #EF0000 !important;
}

.inputCoALabelError {
    color: #EF0000 !important;
}

.inputGroupCoA .rs-input-group:active {
    border: 2px solid rgba(52, 135, 240, 0.48);
}

.inputGroupCoA.rs-input-group:not(.rs-input-group-inside):not(.rs-input-group-disabled):hover, .rs-input-group:not(.rs-input-group-inside):not(.rs-input-group-disabled).rs-input-group-focus {
    border: 2px solid rgba(52, 135, 240, 0.48);
}

.inputGroupCoA .rs-input-group:not(.rs-input-group-inside):not(.rs-input-group-disabled):hover, .rs-input-group:not(.rs-input-group-inside):not(.rs-input-group-disabled).rs-input-group-focus {
    border-color: rgba(52, 135, 240, 0.48) !important;
}

/*.notActiveColor.rs-picker-input:not(.rs-picker-disabled):hover, .rs-picker-input.rs-picker-focused {
    border-color: #e0e0e0 !important;
}*/
/*.notActiveColor .rs-picker-input:hover{
    border-color: #e0e0e0;
}*/

.rs-picker-toggle-clean, .rs-picker-toggle-caret{
    transform: translateY(2px);
}

.notActiveColor.rs-picker-input:not(.rs-picker-disabled):hover {
    border-color: #e0e0e0 !important;
}

.notActiveColor.rs-picker-input.rs-picker-focused {
    border-color: #e0e0e0 !important;
}

.inputGroupCoA .rs-btn-default:hover {
    background-color: transparent;
}

.inputGroupCoA .rs-btn-default:active {
    background-color: transparent;
}

.inputGroupCoA .rs-btn-default:active:hover {
    background-color: transparent;
}

.selectCoA.rs-picker-default:not(.rs-picker-disabled) .rs-btn:hover, .rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle:hover, .rs-picker-default:not(.rs-picker-disabled) .rs-btn.active, .rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle.active:active {
    border: 2px solid rgba(52, 135, 240, 0.48);
}


.selectCoAError.rs-picker-default .rs-picker-toggle {
    border: solid 2px #EF0000 !important;
}


.inputCoA {
    height: 40px;
    background: #FFFFFF;
    border-radius: 4px;
}

.inputCoA.rs-input:active {
    border: 2px solid rgba(52, 135, 240, 0.48);
}

.inputCoA.rs-input:hover {
    border: 2px solid rgba(52, 135, 240, 0.48);
}

.inputCoA.rs-input:focus {
    border: 2px solid rgba(52, 135, 240, 0.48);
}

.rsformdisplay {
    display: flex;
    align-items: flex-end;
    margin-bottom: 0 !important;
}


.form-control-signUP {
    display: flex;
    flex-direction: column;
    margin: 16px 0 16px 0;
}

.sign-up-footer-container {
    display: flex;
    justify-content: center;

}

.sign-up-footer {
    font-family: Public Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 16px;
    /* identical to box height, or 145% */

    display: flex;
    align-items: center;
    text-align: center;
    /* Transparency / Black 48 */

    color: rgba(41, 41, 41, 0.48);
}
