.previsualizer-container{
    position: fixed;
    z-index: 50;
    right: 50px;
    width: 50%;
    height: 100%;
}
.select-overlays{
    width: 40%;
    margin: 10px !important;
}
.form-previsualizer{
    display: flex;
    width: 100% !important;
}
.btn-export{
    bottom: 10px;
}