.end-of-trial{
    position: relative;
    display: flex;
    width: 500px;
    height: 500px;
    .window{
        position: absolute;
    }
    .end-of-trial-badge{
        position: absolute;
        bottom: 0;
        right: -200px;
    }
}
.end-trial-tittle{
        font-family: 'Public Sans';
        font-style: normal;
        font-weight: 500;
        font-size: 42px;
        line-height: 40px;
        /* identical to box height, or 133% */

        display: flex;
        align-items: center;
        text-align: center;

        /* Neutrals / Alpha / 048 */

        color: rgba(41, 41, 41, 0.48);
        margin-bottom: 5px;
    }
    .end-trial-p{
        margin-top: 5px;
        width: 70%;
        text-align: center !important;
        font-family: 'Public Sans' !important;
        font-style: normal !important;
        font-weight: normal !important;
        font-size: 18px !important;
        line-height: 24px !important;
        /* or 133% */

        text-align: center !important;

        /* Neutrals / Alpha / 072 */

        color: rgba(41, 41, 41, 0.72) !important;
    }