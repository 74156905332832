.warning-not-mobile-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100dvh;

    .logo-container {
        margin-top: 10px;
        margin-bottom: 10px;
    }
    .warning-message-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;

        h3{
            font-family: 'Public Sans';
            font-style: normal;
            font-weight: normal;
            font-size: 30px;
            line-height: 40px;
            margin-left: 20px;
            margin-right: 20px;
            /* identical to box height, or 133% */
    
            display: flex;
            align-items: center;
            text-align: center;
    
            color: rgba(41, 41, 41, 0.48);
        }
    }
}