.forgot-container{
    display: flex;
    
    position: relative;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-bottom: 150px;
    .new{
        position: absolute;
        display: flex;
        justify-content: center;
        left: -130px;
        top:-80px;

    }
    .old{
        position: absolute;
        display: flex;
        justify-content: center;
        z-index: 50;
       
    }
}
.forgot-input-container{
    display: flex;
    flex-direction: row;
    .rounded-button {
        height: auto;
        width: 120px;
    }
    .input{
        width: 500px;
       
        margin-right: 50px;
    }
    .changepass{
        width: 150px;
    }
}

.change-form{
    width: 15%;
    margin-top: 15px;
    .change-form-inputs{
        margin-bottom: 10px;
        margin-top: 10px;

        &.button{
            display: flex;
            justify-content: center;
            .rounded-button{
                    height: 50px;
            }
        }
    }
}

.back-container{
    margin-right: 32px;
    margin-top: 28px;
    display: flex;
    justify-content: flex-end;
    .icon-close::before{
        font-size: 18px;
    }
}