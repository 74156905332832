

.elipsis-menu-container{
    position: absolute;
    width: 300px;
    height: 300px;
}
.drop-menu-container.card{
    top: -270px;
}

.menu-surface{
    padding: 8px;
}