.registerHeader {
  width: 100%;
  height: 72px;

  background: #FFFFFF;
  box-shadow: inset 0px -1px 0px rgba(41, 41, 41, 0.12);
}

.headerImageMargin{
  margin: 10px 16px;
  /* Neutrals / White */
}