.card-container {
  flex-wrap: wrap;
  margin-top: 32px;
  max-width: 352px;
  width: 352px;
  cursor: pointer;

}

.anchorTag .menu-item:hover {
  background: #3487F0;
  color: white;
  cursor: pointer;
}

.anchorTag:hover {
  color: #ffffff !important;
}

.anchorTag {
  font-family: Public Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  align-items: center;
  color: white;
  width: 100%;
}

.card-row {
  padding: 0 16px 0 16px;
}

.card {
  padding: 16px;
  background: #FFFFFF;
  box-shadow: 0px 4px 12px rgba(41, 41, 41, 0.08);
  border-radius: 2px;
  max-height: 200px;
  height: 200px;
}

.card:hover {
  box-shadow: 0px 4px 12px rgba(41, 41, 41, 0.12);
}

.campaign-name {
  font-family: Public Sans;
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 24px;
}

.experiences-counter {
  padding-top: 15px;
  margin-bottom: 5px;
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 16px;
  padding-bottom: 5px;
}

.campaign-experience-preview {
  width: 48px;
  height: 48px;
}

.campaign-experience-more-placeholder {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  right: -15px;
  width: 48px;
  height: 48px;
  background: rgba(41, 41, 41, 0.96);
  border-radius: 3px;
}

.campaign-experience-more-placeholder > .campaign-experience-more-placeholder-value {
  color: #FFFFFF;
}

.campaign-experience-preview + .campaign-experience-preview {
  margin-left: 20px;
}

.campaign-time {
  color: rgba(41, 41, 41, 0.50);
  font-size: 11px;
}

.campaign-img-container {
  position: relative;
}

.campaign-img-container > .campaign-experience-preview:first-child {
  margin-left: 8px;
}

.campaign-actions {
  margin-top: 12px;
  display: flex;
  align-items: center;
}

.campaign-action.icons {
  width: auto;
  display: flex;
  justify-content: space-between;
  color: rgba(41, 41, 41, 0.24);
}

.campaign-action.icons > .value {
  display: flex;
  align-self: center;
  font-family: Public Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  margin-left: -25px;
  color: #292929;
}

.campaing-action.elipsis {
  display: flex;
  justify-content: flex-end;
}

.campaing-action.elipsis > .icon-elipsis {
  font-size: 24px;
}

.truncateTitle {
  width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/*-------------------------*/
.card-experience {
  &.description-block {
    display: flex;
    flex-direction: column;
  }

  &.title {
    cursor: default;
    font-family: 'Public Sans';
    font-style: normal;
    font-weight: bold;
    width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;
    background: white;
    resize: horizontal;
    font-size: 17px;
    line-height: 20px;
    letter-spacing: -0.0125em;
    color: #292929;
  }

  &.time {
    font-family: Public Sans;
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 16px;
    display: flex;
    align-items: center;
    color: rgba(41, 41, 41, 0.48);
    margin-top: 4px;
    margin-right: 16px;
    margin-left: 16px;
    width: 100%;

  }

  &.actions {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;


  }

}

.top-actions {
  display: flex;
  flex-direction: row;
  position: absolute;

  .world-action {
    display: flex;
    justify-content: center;
    align-content: center;
    width: 40px;
    height: 24px;
    background-color: #292929;
    z-index: 1;
    color: #FFFFFF;
  }

  .publish-action {
    display: flex;
    justify-content: center;
    align-content: center;
    width: 40px;
    height: 24px;
    background-color: #3487F0;
    z-index: 10;
    color: #FFFFFF;
  }
}

.marginImage {
  margin-right: 10px;
}

.marginLefText {
  margin-right: 10px;
}

.card-experience.description-block {
  width: 100%;
}

.marginLeft > div {
  margin-left: 9px;
}


.menu-footer {
  display: flex;
  justify-content: center;
  justify-self: center;
}


.rmwc-collapsible-list__children-inner {
  padding-top: 15px;
  padding-bottom: 15px;
}

.mdc-card__action-buttons > .hover-buttons {
  position: absolute;
  display: flex;
  left: 0;
  font-size: 21px;
  margin-left: 15px;
  color: rgba(41, 41, 41, 0.48);


}

.hover-buttons {

  .hover-scan {
    cursor: pointer;
    margin-right: 10px;
  }

  .hover-eye {
    cursor: pointer;
  }

  .hover-eye:hover {
    color: var(--primary-color);
  }

  .hover-scan:hover {
    color: var(--primary-color);
  }

}

.mdc-card__action.mdc-card__action--icon.mdc-ripple-upgraded--unbounded.mdc-ripple-upgraded.mdc-card__action.mdc-card__action--icon.mdc-icon-button {
  display: flex;
  align-items: center;
}

.mdc-card__action-buttons + .mdc-card__action-icons {
  margin: auto !important;
  position: absolute;
}

.mdc-menu-surface--anchor {
  .icon-ellipsis:before {
    margin: 0;
  }
}

.mdc-list {
  .menu-item {
    padding: 8px 16px 8px 16px;
    margin-bottom: 0;
    color: #FFFFFF;
  }
}

.experience-card {
  border-radius: 8px !important;
  transition: all .25s linear;
  box-shadow: 0px 4px 8px 0px rgba(41, 41, 41, 0.12), 0px 0px 2px 0px rgba(41, 41, 41, 0.18) !important;
}

.experience-card:hover {
  box-shadow: 0px 12px 16px 0px rgba(0, 0, 0, 0.12), 0px 0px 48px 0px rgba(41, 41, 41, 0.12) !important;
}

.custom-slider .rs-carousel-slider-item {
  background: white;
}

.custom-slider .rs-carousel-label {
  background: gray;
}

.custom-slider .rs-carousel-label:hover {
  background: gray;
}

.slick-next:before, .slick-prev:before {
  color: #0a0a0a !important
}

