@font-face {
    font-family: 'coaFontLight';
    src: url('https://cdn-studio.camonapp.com/_loader/brand/base/css/CarbonOT-Regular.ttf');
}

@font-face {
    font-family: 'coaFontBold';
    src: url('https://cdn-studio.camonapp.com/_loader/brand/base/css/CarbonOT-Bold.ttf');
}

@font-face {
    font-family: 'Arimo';
    src: url('https://cdn-studio.camonapp.com/_loader/brand/base/css/Arimo-VariableFont_wght.ttf');
}

@font-face {
    font-family: 'Helvetica Neue LT Std';
    src: url('https://cdn-studio.camonapp.com/_loader/brand/base/css/HelveticaNeueLTStd-Roman.otf');
}

@font-face {
    font-family: 'Barlow';
    src: url('https://cdn-studio.camonapp.com/_loader/brand/base/css/Barlow-Medium.ttf');
}

@font-face {
    font-family: 'Milo Pro';
    src: url('https://cdn-studio.camonapp.com/_loader/brand/base/css/Milo Pro-Medium.otf');
}

@font-face {
    font-family: 'Nunito-Bold';
    src: url('https://cdn-studio.camonapp.com/_loader/brand/base/css/Nunito-Bold.ttf');
}


@font-face {
    font-family: 'Nunito-Light';
    src: url('https://cdn-studio.camonapp.com/_loader/brand/base/css/Nunito-Light.ttf');
}

@font-face {
    font-family: 'D-DIN';
    src: url('https://cdn-studio.camonapp.com/_loader/brand/base/css/D-DIN-Bold.otf');
}

@font-face {
    font-family: 'Proxima Nova';
    src: url('https://cdn-studio.camonapp.com/_loader/brand/base/css/ProximaNova-Bold.ttf');
}

@font-face {
    font-family: 'Futura Std';
    src: url('https://cdn-studio.camonapp.com/_loader/brand/base/css/FuturaStd-Book.otf');
}

@font-face {
    font-family: 'Proxima Nova';
    src: url('https://cdn-studio.camonapp.com/_loader/brand/base/css/ProximaNova-Regular.ttf');
}

@font-face {
    font-family: 'GothamRnd';
    src: url('https://cdn-studio.camonapp.com/_loader/brand/base/css/GothamRnd-Bold.otf');
}

.custom-loader-sidebar {
    overflow-y: scroll;
    overflow-x: hidden;
    height: calc(100% - 150px);
}

.text-field-text {
    height: 40px;
    width: 219px;
    margin-left: 11px;
    border-bottom: 0px solid;
    font-size: 1rem;
}

.new-logo-preview {
    max-width: 220px;
}

.FooterColorPicker {
    margin-bottom: -12px;
}

.menu-title{
    font-family: 'Public Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 28px !important;
    line-height: 40px;
    color: #000000;
    text-align: center;
}

.menu-subtitle{
    font-family: 'Public Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #000000;
    opacity: 0.72;
}

.item-open{
    background-color: #E8F2FD !important;
    color: #4393EF !important;
}

.item-close:hover{
    background-color: #E8F2FD !important;
    color: #4393EF;

}

#simple-select {
    margin-left: 13px;
    font-size: 1rem;
    margin-top: 20px;
    margin-bottom: 10px;
    height: 40px;
}

#simple-select Select {
    height: 40px;
}

#simple-select-label {
    height: 40px;
}

.text-field{
    margin-left: 2px;
}

/* .MuiOutlinedInput-root {
    height: 40px;
    width: 180px;
} */

.text-field-text{
    padding: 10px;
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    color: rgba(0, 0, 0, 0.48);
    width: 180px;
    height: 40px; 
}
.select-footer-theme-color, .select-language, .select-header-font {
    padding: 10px;
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    color: rgba(0, 0, 0, 0.48);
    width: 180px;
    height: 40px; 
    width: 100%;
    margin-left: 11px;
    background-color: white;
    font-size: 1rem;
}

.screen-selector-container {
    background-color: white;
    text-align: center;
    z-index: 1;
    position: sticky;
    top: 0;
    padding-bottom: 10px;
    padding-top: 10px;
    width: 100%;
}

.screen-selector-select {
    padding: 10px;
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    color: rgba(0, 0, 0, 0.48);
    width: 210px;
    height: 40px;
    background-color: white;
    font-size: 1rem;
}

.footer-container{
    display: flex;
    flex-direction: column;

}
.menu-item-custom{
    color: #000000 !important;
    font-family: 'Public Sans';
    font-style: normal;
    font-weight: 600 !important;
    font-size: 14px;
}

.background-item-container {
    width: 100%;
}

.label-color-picker {
    position: absolute;
    top: 0;
    left: 40px;
    background-color: white;
    z-index: 1;
    font-size: 12px;
    padding-left: 5px;
    padding-right: 5px;
}