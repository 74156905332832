.layout-title-container {
    display: flex;
    flex-direction: row;
    cursor: default;
    justify-content: space-between;
}

.go-back-button {
    width: auto;
}

.go-back-button:hover {
    cursor: pointer;
    opacity: 0.8;
}

.buttons-container {
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 8px;
}

.discard-changes-button {
    font-size: 10px;
    text-decoration: underline;
    color: #3487F0;
    cursor: pointer;
}

.sidebar {
    overflow-y: visible;
    overflow-x: visible;
    height: 100%;
}
.content-overlay{
    padding-right: 40px;
    padding-left: 40px;
    width: 80vw;
    padding-top: 90px;
    overflow-x: hidden;
}
.line{
    border-top: 2px solid rgba(0, 0, 0, 0.12);;
}