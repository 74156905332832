.sidebar-container {
    width: 248px;
    padding-bottom: 0;
    background: #FFFFFF;
    height: calc(100% - 70px);
    display: flex;
    flex-direction: column;
    
}

.sidebar-container.collapsed {
    width: 72px;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
}

.button-container {
    height: 52px;
    display: flex;
    align-items: center;
    margin-top: 62px;
}

.button-container .mdc-button__label {
    /*margin-bottom: 5px;*/
}

.button-container > button {
    font-family: 'Space Mono';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    position: absolute;
    right: -8px;
    width: 105%;
    padding: 16px;
    box-shadow: 0px 4px 12px rgba(41, 41, 41, 0.08);
}

.collapsable-container {
    width: 100%;
}

.sidebar-collapser {
    font-size: 28px;
    color: var(--primary-color);
    cursor: pointer;
    position: absolute;
    top: 15px;
    left: 15px;
}

.sidebar-collapser.uncollapsed {
    animation: nocollapsed 0.4s ease-out forwards;
}

.sidebar-collapser.collapsed {
    animation: collapsed 0.4s ease-out forwards;
    left: 20px;
}

.menu-item.report .label-report .collapsed {
    margin-left: 7px;
}

@keyframes nocollapsed {
    0% {
        transform: rotate(180deg);
    }
    100% {
        transform: rotate(0deg);
    }
}

@keyframes collapsed {
    0% {
        transform: rotate(0);
    }
    100% {
        transform: rotate(180deg);
    }
}

.add-collapsed {
    position: absolute;
    height: 50px;
    border-radius: 50px;
    font-size: 24px !important;
    right: -10px !important;
}

.menu-container {
    margin-left: 0;
    margin-right: 0;
}

.menu {
    list-style: none;
    display: flex;
    flex-direction: column;
    margin: 0;
    margin-left: -4px;
    padding: 8px 0 8px 15px;
    width: 100%;
}

.menu-item {
    display: flex;
    flex-direction: row;
    font-family: Public Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    align-items: center;
    padding: 15px 8px 15px 8px;
    margin-bottom: 5px;
    color: #292929;
    height: 40px;
}

.menu-item-dark {
    display: flex;
    flex-direction: row;
    font-family: Public Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
    align-items: center;
    padding: 16px;
    color: white;
    height: 32px;
}

.menu-item-dark:hover {
    background: #3487F0;;
    color: white;
    cursor: pointer;
}

.menu-item-gray {
    display: flex;
    flex-direction: row;
    font-family: Public Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
    align-items: center;
    padding: 16px;
    color: gray;
    height: 32px;
}

.menu-item.report {
    height: 40px;
}

.menu-item.report .label-report {
    display: flex;
    align-items: center;
    margin-right: 75px;
}

.menu-disabled {
    opacity: 0.5;
    cursor: default !important;
}

.menu-item:hover {
    background: #F3F3F3;
    color: #292929;
    cursor: pointer;
    border-radius: 4px;
}


.icon-menu {
    color: rgba(41, 41, 41, 0.72);
    margin-right: 8px;
    font-size: 16px;
}

.icon-menu.collapsed {
    margin: auto;
    font-size: 18px;
}

.colorBlue {
    background: rgba(52, 135, 240, 0.12);
    border-radius: 4px;
    color: #3487F0 !important;
    cursor: pointer;
}

.active > .menu-item {
    background: rgba(52, 135, 240, 0.12);
    border-radius: 4px;
    color: #3487F0 !important;
    cursor: pointer;

}

.sidebar-footer {
    display: flex;
    flex-direction: column;
    width: 176px;
    height: 72px;
    position: absolute;
    bottom: 25px;
}

.sidebar-footer > .title {
    font-family: 'Public Sans';
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 16px;
    display: flex;
    align-self: center;
    align-items: center;
    text-align: center;
    color: #292929;
}

.sidebar-footer > .title > .time {

    font-weight: bold;
}

.outlined {
    width: 80%;
}

.crown-collapsed {
    font-size: 24px;
}

.outlined.red {
    color: #ff4672;
    background-color: #ffffff !important;
    border: 1px solid #ff4672;
    box-sizing: border-box;
}

.add-uncollapsed {
    height: 50px;
    border-radius: 50px;
}

.add-uncollapsed > .mdc-button__label {
    position: absolute;
}

.add-collapsed > .mdc-button__label {
    position: absolute;

}
