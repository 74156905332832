.ReportGraphicBarChart-container {
  margin-top: 10px;
  /* Neutrals / White */

  background: #FFFFFF;
  /* Elevation / (Low) Card rest */
  height: 320px;
  box-shadow: 0px -1px 2px rgba(41, 41, 41, 0.24), 0px 2px 4px rgba(41, 41, 41, 0.12);
  border-radius: 8px;
}

.classChart {
  width: 1558px;
  height: 320px !important;
}