.menu-selector-full{
    width: 100%;
}

.list-search-container{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    border-radius: 5px;

    &.typed{
        padding: 0 10px 0 10px;
        background-color: rgba(52,135,240,0.12);
    }
    .text{
        color: var(--primary-color);
    }
    .add-icon{
        color: var(--primary-color);
        font-size: 16px;
    }
}
.drop-select__control{
    height: 50px;
}
.divider{
    margin-top: 10px;
}

.list-search-button{
    background-color: var(--primary-color) !important;
    color: #ffffff !important;
    position: absolute;
    right: 20px;
}

.list-search-add-campaign{
    background-color: rgba(41, 41, 41, 0.04) !important;
    color: var(--primary-color) !important;
    position: absolute;
    right: 20px; 
    z-index: 1;
    
}
