.color-picker {
    margin-left: 30px;
}

.color-picker-container {
    max-height: 40px;
    margin-bottom: 4px;
    padding-left: 13px;
    width: 216px;
}

.cover {
    position: 'fixed';
    top: '0px';
    right: '0px';
    bottom: '0px';
    left: '0px';
}

.text-field-color {
    height: 40px;
    width: 170px;
    margin-right: 10px;
    font-size: 1rem;
    color: rgba(0, 0, 0, 0.48) !important;
}

.span-color {
    appearance: none;
    background-color: transparent;
    border: 1px solid rgba(0, 0, 0, 0.12);
    padding: 0px !important;
    height: 40px;
    width: 40px;
    border-radius: 4px !important;
    cursor:pointer
}
.span-color::-webkit-color-swatch{
    border-radius: 4px;
    border: none;
}

.color-picker-row {
    display: flex;
    flex-direction: row;
}
