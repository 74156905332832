.sign-in-container {
    .sign-form{
        width: 100%;
        display: flex;
        flex-direction: column;
        color: #292929;
    }
}
.sign-in-title{
    font-family: 'Public Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 40px;
    color: #292929;
    margin-top: 64px;
    margin-bottom: 64px;
}
.sign-in-title span{
    font-weight: 700;
}
.form-control-signUP{
    display: flex;
    flex-direction: column;
    margin: 15px 0 15px 0;

    .login-button{
        border-radius: 200px;
        height: 55px;
        font-family: 'Space Mono';
        font-size: 17px;
        font-weight: 400;
        text-transform: none;
        letter-spacing: -0.05em;
    }
    .sign-in-forgot{
        width: fit-content;
        cursor: pointer;
        padding-top: 15px;
        font-family: 'Space Mono';
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 24px;
        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: -0.05em;
        color: var(--primary-color);
        margin-bottom: 56px;
    }
    .error{
        font-family: 'Public Sans';
        font-style: normal;
        font-weight: normal;
        font-size: 11px;
        line-height: 16px;
        /* or 145% */
        text-align: initial;
        margin-top: 12px;

        /* Messages / Error */

        color: #ED4331;
    }
}
.sign-in-forgot{
    margin-left: 16px;
    width: fit-content;
    cursor: pointer;
    padding-top: 15px;
    font-family: 'Space Mono';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.05em;
    color: var(--primary-color);
}

.forgotPass-button{
    align-items: center;
    padding: 8px 20px;
    width: 88px;
    height: 40px;

    /* Brand / Studio */

    background: #3487F0;
    /* Buttons / Containers / Studio rest */

    box-shadow: 0px -1px 2px rgba(52, 135, 240, 0.12), 0px 2px 4px rgba(52, 135, 240, 0.24);
    border-radius: 200px;
}


.form-control{
    display: flex;
    flex-direction: column;
    margin: 2px 0 2px 0;

    .login-button{
        border-radius: 200px;
        height: 55px;
        font-family: 'Space Mono';
        font-size: 17px;
        font-weight: 400;
        text-transform: none;
        letter-spacing: -0.05em;
    }
    .sign-in-forgot{
        width: fit-content;
        cursor: pointer;
        padding-top: 15px;
        font-family: 'Space Mono';
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 24px;
        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: -0.05em;
        color: var(--primary-color);
        margin-bottom: 56px;
    }
    .error{
        font-family: 'Public Sans';
        font-style: normal;
        font-weight: normal;
        font-size: 11px;
        line-height: 16px;
        /* or 145% */
        text-align: initial;
        margin-top: 12px;

        /* Messages / Error */

        color: #ED4331;
    }
}
.sign-in-actions{
    padding-top: 24px;
    .new{
        font-family: 'Public Sans';
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 24px;
        margin-right: 5px;
    }
    .sign-up{
        cursor: pointer;
        font-family: 'Space Mono';
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 24px;
        color: #3487F0;
        text-decoration: underline;
    }
}
.mdc-floating-label{
    color: #000 !important;
}

.rmwc-circular-progress__circle{
    color: #fff;
}

.login-button{
    .mdc-button__icon{
        .rmwc-circular-progress{
            font-size: 24px;

        }
    }
}

.mdc-button--raised:disabled, .mdc-button--unelevated:disabled {
    background-color: var(--primary-color) !important;
    opacity: 0;
    color: #fff !important;
}
.formTestWebkit {
    .mdc-text-field__input {
        font-family: 'Public Sans';
    }
}