.container-tiles {
  width: 99.8%;
  margin-left: 2px;
  margin-top: 20px;
  margin-bottom: 10px;
  background: #FFFFFF;
  /* Elevation / (Low) Card rest */
  height: 320px;
  box-shadow: 0px -1px 2px rgba(41, 41, 41, 0.24), 0px 2px 4px rgba(41, 41, 41, 0.12);
  border-radius: 8px;
}

.tableMargin{
  margin-left: 10px;
  margin-right: 7px;
}

.container-tilesBot{
  margin-top: 20px;
  margin-bottom: 35px;
  background: #FFFFFF;
  /* Elevation / (Low) Card rest */
  height: 320px;
  box-shadow: 0px -1px 2px rgba(41, 41, 41, 0.24), 0px 2px 4px rgba(41, 41, 41, 0.12);
  border-radius: 8px;
}

.breadStyles {
  padding:0px !important;
  margin-top: 10px;
  font-family: Public Sans;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 32px;
}
.breadStyles a:hover, a:focus{
  color: #575757;
}




.datepickerpos {
  margin-right: 10px;
}

.datepickerpos .rs-picker-toggle-clean{
  display: none;
}

.text-center {
  margin-top: 10px;
  text-align: center;
  font-family: Public Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #292929;
}

.demog-text {
  padding: 30px;
  font-family: Public Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  color: #292929;
}

.text-statics {
  margin-top: 15px;
  margin-left: 10px;
  font-family: Public Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  color: #292929;
}

.subtext-statics {
  margin-top: 10px;
  margin-left: 10px;
  font-family: Public Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 16px;
  color: #292929;
}

.colheight {
  height: 10px;
}