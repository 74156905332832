.edit-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 300px;
}
.edit-previsualizer{
    width: 200px;
    height: 150px;
}
.edit-select{
    width: 100%;
}
.edit-input-color{
    width: 85%;
    margin-right: 5px !important;
}