.apply-changes-button:hover {
    color: rgba(41, 41, 41, 0.12);
}

.save-button{
    border-radius: 200px !important;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    box-shadow: 0px 2px 4px rgba(67, 147, 239, 0.06), 0px 4px 8px rgba(67, 147, 239, 0.24), 0px 1px 4px rgba(67, 147, 239, 0.12);
    margin-bottom: 15px !important;
    margin-top: 15px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.75;
    letter-spacing: 0.02857em;
    text-transform: uppercase;
    min-width: 64px;
    padding: 6px 16px;
    user-select: none;
    outline: 0;
    justify-content: center;
    position: relative;
    box-sizing: border-box;
    border: 0;
    align-items: center;
    -webkit-box-pack: center;
    margin: 0;
    vertical-align: middle;
    cursor: pointer;
    text-decoration: none;
}

.save-button:not(:disabled) {
    background-color: #4393EF;
    color: white;
}

.save-button:disabled {
    background-color: #d9d9d9;
    color: white;
}

.save-button:hover:not(:disabled){
    text-decoration: none;
    box-shadow: 0 2px 8px -1px gray !important;
    background-color: rgba(52, 135, 240, 0.8);
}

.discard-changes-button{
    border-radius: 200px !important;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border: 1px solid rgba(25, 118, 210, 0.5);
    color: #1976d2;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.75;
    letter-spacing: 0.02857em;
    text-transform: uppercase;
    min-width: 64px;
    text-decoration: none;
    user-select: none;
    vertical-align: middle;
    cursor: pointer;
    padding: 6px 16px;
    background-color: transparent;
    outline: 0;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    position: relative;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
}
.discard-changes-button:hover{
    text-decoration: none;
    background-color: rgba(25, 118, 210, 0.04);
    border: 1px solid #1976d2;
}