.not-found-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    h3{
        font-family: 'Public Sans';
        font-style: normal;
        font-weight: normal;
        font-size: 30px;
        line-height: 40px;
        /* identical to box height, or 133% */

        display: flex;
        align-items: center;
        text-align: center;

        color: rgba(41, 41, 41, 0.48);
    }
    p{

        font-family: Public Sans;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        /* identical to box height, or 150% */

        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: -0.0125em;

        /* Neutrals / Alpha / 072 */

        color: #3487F0;
    }
}