.main{
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.zoom{
    transform: scale(0.35);
    width: 100%;
    height: 100%;
}
.desktop-visualiser{
    position: relative;
    width: 534px;
    height: 400px;
    border-radius: 0px;
    /* border: 1px solid black; */
    border: 50px solid transparent  ;
    -webkit-border-image:url("./desktop-overlay.png") 100   100    repeat; /* Safari */
    -o-border-image:url("./desktop-overlay.png") 100    100     repeat; /* Opera */
    border-image:url("./desktop-overlay.png") 100   100    repeat;
   
}
.modal-visualiser{
    position: relative;
    width: 534px;
    height: 400px;
    border-radius: 0px;
    /* border: 1px solid black; */
    border: 50px solid transparent  ;
}

.desktop-visualiser-contain {  
    border: 1px solid #6060603d;
    border-top: none; 
    position: relative;
    width: 534px;
    height: 278px;
    margin-left: -50px;
    margin-top: -31px;
}

.desktop-mobile{
   position: relative;
   width: 180px;
   height: 340px;
    border-radius: 0px;
    border: 1px solid black;
    /* background-image: url(   ""); */

        /* background-image: url("./celular-overlay.png");
    background-size: contain;
    background-position-x: 52%;
    background-position-y: 58%;
    background-repea    t: no-repeat; */

    border: 50px solid transparent  ;
  -webkit-border-image:url("./celular-overlay.png") 100   100    repeat; /* Safari */
  -o-border-image:url("./celular-overlay.png") 100    100     repeat; /* Opera */
  border-image:url("./celular-overlay.png") 100   100    repeat;
}
.desktop-mobile-contain {   
    position: relative;
    width: 154px;
    height: 319px;
    margin-left: -37px;
    margin-top: -36px;
    border-radius: 8px;
}
.options{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 2%;
    cursor: pointer;
    color: gray;
}

.active{
    color: lightskyblue !important  ; 
}

.option-desktop{
    margin-right: 10px;
}
.item-container{
    width: auto !important;
    margin-top: 0px !important;
    margin-left: 10px !important;
}
.item-select{
    width: auto !important;
    margin-top: 0px !important;
}
.item-overlay{
    width: 40%;
    margin: 10px !important;
    margin-left: 0px !important;
}
.item-color{
    width: 60%;
    margin: 10px !important;
    margin-left: 0px !important;
}

.color-picker-CL{
    appearance: none;
    background-color: transparent;
    border: 1px solid rgba(0, 0, 0, 0.12);
    padding: 0px !important;
    height: 40px;
    width: 40px;
    border-radius: 4px !important;
    cursor:pointer
}
.overlay{
    position: absolute !important;
}
.modal-container{
    position: fixed;
    width: 80% !important;
}