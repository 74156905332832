@mixin keyframes($animation-name) {
  @-webkit-keyframes #{$animation-name} {
    @content;
  }
  @-moz-keyframes #{$animation-name} {
    @content;
  }
  @-ms-keyframes #{$animation-name} {
    @content;
  }
  @-o-keyframes #{$animation-name} {
    @content;
  }
  @keyframes #{$animation-name} {
    @content;
  }
}

@mixin animation($str) {
  -webkit-animation: #{$str};
  -moz-animation: #{$str};
  -ms-animation: #{$str};
  -o-animation: #{$str};
  animation: #{$str};
}


.onboard-container {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 92vh;
  background-color: #fff;
  z-index: 6000;

  h3 {
    font-family: 'Public Sans';
    font-style: normal;
    font-weight: normal;
    font-size: 30px;
    line-height: 40px;
    /* identical to box height, or 133% */

    display: flex;
    align-items: center;
    text-align: center;

    color: #292929;
  }

  p {

    font-family: Public Sans;
    font-style: normal;
    font-weight: normal;
    /* padding: 18px; */
    margin-top: 16px;
    margin-bottom: 48px;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.0125em;
    color: rgba(41, 41, 41, 0.72);

  }

  .amination-ob {
    display: flex;
    flex-direction: row;
    position: relative;

    .grid-container-image {
      position: absolute;
      align-self: center;
      right: -300px;
      bottom: 50px;
      @include animation('onboardMove 5s ease-in-out  infinite');
    }

    .grid-container-cursor {
      position: absolute;
      bottom: 30px;
      right: 30px;
      @include animation('onboardCursor 5s ease-in-out infinite');
    }
  }

  .rounded-button {
    font-size: 17px;
    line-height: 24px;
    padding: 16px 24px;
    height: 56px;
    background-color: var(--primary-color);
    color: #fff;
  }

}


@include keyframes(onboardMove) {

  90%, 100% {
    right: 100px;
  }
}

@include keyframes(onboardCursor) {

  20%, 100% {

    bottom: 280px;
    right: 450px;
  }
}
    