.campaign-container {
  margin-top: 32px;
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  flex-wrap: wrap;
}

.campaign-container .mdc-card__actions {
  padding: 0px;
}

.campaign-exp-card {
  margin-top: 10px;
  margin-left: 15px;
  margin-right: 15px;
}

.side-menu {
  padding-left: 30px;
}
