.add-user-form{
    width: 400px;

    display: flex;
    flex-direction: column;
    .form-item{
        margin: 10px 0 10px 0;
    }
}

