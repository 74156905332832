:root {
    --main-brand-color: #000;
    --secondary-brand-color: #000;
    --background-color: #fff;
    --txt-main-color: #000;
    --btn-txt-main-color: #000;
    --btn-txt-secondary-color: #fff;
    --btn-main-color: #000;
    --btn-secondary-color: #fff;
    --btn-deny-color: #b2b2b2;
    --btn-deny-txt-color: #fff;
}
 
@font-face {
    font-family: 'coaFontLight';
    src: url('https://s3.amazonaws.com/stage.assets.camonapp.com/_loader/brand/base/css/CarbonOT-Regular.ttf');
}

@font-face {
    font-family: 'coaFontBold';
    src: url('https://s3.amazonaws.com/stage.assets.camonapp.com/_loader/brand/base/css/CarbonOT-Bold.ttf');
}



* {
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
    -webkit-tap-highlight-color: transparent;
}

/* .bold {
    font-family: 'coaFontBold';
} */

.noselect {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.a-canvas {
    width: 100%!important;
    height: 100%!important;
    top: 0!important;
    left: 0!important;
    right: 0!important;
    bottom: 0!important;
    position: fixed!important;
}

#sf_body {
    height: 100% !important;
}

#sf_align {
    height: 100% !important;
    width: 100% !important;
    top: 0px !important;
    left: 0px !important;
}

#root-container {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
}

#container {
    width: 100%;
    height: 100%;
}

#experience-container {
    width: 100%;
    height: 100%;
}

.no-select {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.background {
    width: 100%;
    height: 100%;
    position: absolute;
    pointer-events: none;
    font-family: 'coaFontLight';
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}

.start-360 {
    background: var(--main-brand-color);
    border-radius: 15px;
    border: 2px solid var(--main-brand-color);
    width: 115px;
    height: 28px;
    cursor: pointer;
    top: 16px;
    right: 16px;
    font-family: coaFontLight;
    font-size: 12px;
    color: #fff;
}

.start-360-active,
.start-360:active {
    background: #fff;
    color: var(--main-brand-color);
}

.start-360:hover {
    background: #fff;
    color: var(--main-brand-color);
}

.start-360 .start-360-logo {
   /* mask-image: url("./img/icon_360.svg");
    -webkit-mask-image: url("./img/icon_360.svg");
    */
    /* TODO */
    background-color: #fff;
    mask-repeat: no-repeat;
    -webkit-mask-repeat: no-repeat;
    mask-size: 18px;
    -webkit-mask-size: 18px;
    width: 18px;
    height: 18px;
    margin: 5px 0 0 15px;
    float: left;
}

.start-360-active .start-360-logo,
.start-360:active .start-360-logo {
    background-color: var(--btn-main-color);
}

.start-360:hover .start-360-logo {
    background-color: var(--btn-main-color);
}

.start-360 span {
    float: left;
    margin: 7px 0 0 10px;
}

.tap-to-place-helper {
    position: absolute;
    bottom: 0px;
    left: 0px;
    pointer-events: auto;
    cursor: pointer;
    width: 100%;
    font-family: 'coaFontLight';
    text-align: center;
    background: rgb(0, 0, 0);
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%);
    padding: 20px 0px;
    color: #fff;
}

.preloader-header-qr {
    display: none;
    height: 100px !important;
    margin-top: 20px;
}

.preloader-qr {
    margin: 0 auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: none;
}

.preloader-qr-code {
    margin-bottom: 20px;
}

.preloader-qr-code img {
    display: block !important;
    margin: 0 auto;
}

.preloader-qr .qr-description-1,
.preloader-qr .qr-description-2 {
    font-family: 'coaFontLight';
    font-size: 20px;
    text-align: center;
    color: var(--txt-main-color);
}

.preloader-qr .qr-description-1 {
    font-family: 'coaFontBold';
}

.preloader {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 2000;
    pointer-events: none;
    font-family: 'coaFontLight';
    background: var(--background-color);
}

.preloader .loading-indicator {
    width: 180px;
    height: 8px;
    border: 2px solid var(--secondary-brand-color);
    border-radius: 8px;
    position: relative;
    margin: 20px auto 0 auto;
    text-align: center;
}

.preloader .loading-indicator-inner {
    background: var(--secondary-brand-color);
    width: 5px;
    height: 8px;
    border: 2px solid var(--secondary-brand-color);
    border-radius: 10px;
    position: absolute;
    top: -2px;
    left: -2px;
}

.preloader .ig-loading-bar{
    border: solid 2px var(--secondary-brand-color);
    width: 280px;
    height: 8px;
    border-radius: 1.625rem;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

.preloader .ig-loading-bar-inner{
    width: 25px;
    height: 6px;
    border-radius: 1.625rem;
    background-color: var(--secondary-brand-color);
    margin: 0px 1.5px;
    position: relative;
    animation: loading .8s infinite alternate;
    animation-timing-function: ease-in-out;
  }

.preloader-center .preloader-bg {
    height: calc(100% - 40px);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: absolute;
    left: 10px;
    right: 10px;
    top: 0px;
  }
  
.preloader .footer {
    position: absolute;
    bottom: 10px;
    width: 100%;
    text-align: center;
    font-size: 14px;
}

.preloader-header,
.preloader-header-qr {
    position: absolute;
    text-align: center;
    width: 100%;
    height: 160px;
    color: var(--txt-main-color);
}

.preloader-header img {
    width: 40px;
    margin: 40px 0 10px 0;
}

.preloader-center {
    width: 100%;
    height: calc(100% - 160px);
    margin-top: 160px;
    position: relative;
}

.preloader-center .loading,
.preloader-center .error {
    margin: 0 auto;
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 500px;
    max-width: calc(100% - 20px);
}

.preloader-center .loading-ar {
    display: none;
}

.preloader-center .loading-text {
    position: relative;
    margin: 0 auto;
    width: 140px;
    text-align: center;
    font-size: 14px;
    color: var(--txt-main-color);
    margin-top: 10px;
}

.preloader-center .loading-content-preview {
    width: 100%;
    height: 55px;
    position: relative;
    margin: 0 auto;
    text-align: center;
}

.preloader-center .loading-content-preview .logo-3d {
    background-repeat: no-repeat;
    background-position: center;
    height: 100%;
    max-height: 155px;
    background-size: contain;
}

.preloader-header-qr .logo-3d {
    background-size: contain;
    background-position: center;
    height: 100px !important;
    width: 200px !important;
    display: inline-block;
    position: relative;
    background-repeat: no-repeat;
  }


.preloader-center .loading-content-preview span {
    font-size: 60px;
    color: var(--txt-main-color);
}

.preloader .footer .poweredby_ar {
    max-height: 71px;
}

.preload-menu .start-experience {
    cursor: pointer;
    position: absolute;
    bottom: 0px;
    margin-bottom: 10px;
    pointer-events: auto;
    width: 100%;
}

.icon360SVG{
    width: 40px;
    margin: 40px auto 10px auto;
}

@media (max-width: 1366px){
    .preloader-center{
        height: calc(100% - 230px)!important;
    }

    .preloader-qr-code img{
        width: 200px;
        height: 200px;
    }
}

.space-p {
    display: inline;
}

.loader-preview-info, .qr-preview-info {
    font-size: smaller !important;
    color: black !important;
    text-shadow: 0 0 white;
}

.qr-preview-info.brand-logo-info {
    position: absolute;
    width: 100%;
    top: 0;
    text-align: center;
}

.loader-preview-info.header-logo-info {
    position: absolute;
    top: 20px;
    left: 50%;
    transform: translateX(-50%)
}

.loader-preview-info.central-logo-info {
    position: absolute;
    top: 0;
    right: 0;
}

/* max widht 1453 */

.qr_section {
    display: none;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    height: 100%;
  }

 .qr-grill {
    height: calc(100% - 40px);
    /* background-image: dinamically generated */
    background-size: cover;
    background-repeat: no-repeat;
    position: absolute;
    left: 10px;
    right: 10px;
    top: 0px;
    z-index: 1;
}

.qr_logo {
    width: 200px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    z-index: 2; /* only valid for preview */
}

.qr_container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    z-index: 2; /* only valid for preview */
}
.qr_code{
    margin-bottom: 30px;
}
.qr_logo img{
    width: 200px;
}
.qr_container .qr_container--description {
    width: 450px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    z-index: 2; /* only valid for preview */
}
.qr_container .qr_container--description span {
    text-align: center;
    font-size: 20px;
}
.qr_view {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    z-index: 2; /* only valid for preview */
}
.qr_view .qr_view--text {
    margin: 5px auto;
    font-weight: bold;
    font-size: 20px;
}
.qr_view .qr_view--button {
    margin: 5px auto;
    border-radius: 1.625rem;
    padding: 10px 30px;
    border: 2px solid var(--btn-qr-color);
    cursor: pointer;
    font-weight: bold;
    background-color: var(--btn-qr-color);
    font-size: 16px;
}

/* -------------------- GENERAL ----------------------------*/ 
.previewer {
    position:absolute;
    width: 100%;
    height: 100%;
    /* background:0 0/50% 50% rgba(0,0,0,.6); */
}
.previewer .browser-simulator {
    position:absolute;
    left:50%;
    top:50%;
    transform:translate(-50%,-50%);
    width: 500px;
    /* margin: 50px auto; */
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    font-family: Arial, sans-serif;
  }
  
.previewer .browser-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 16px;
    background-color: #f1f1f1;
    border-bottom: 1px solid #ccc;
    border-radius: 8px 8px 0 0;
  }
  
.previewer .favicon {
    width: 16px;
    height: 16px;
    margin-right: 8px;
  }
  
.previewer .title {
    font-size: 14px;
    color: #333;
    font-weight: bold;
  }

  .window-controls {
    display: flex;
    gap: 8px;
  }

.previewer .window-button {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    display: inline-block;
    cursor: pointer;
  }

.previewer .window-button.minimize {
    background-color: #f4c20d; /* Amarillo */
  }

.previewer .window-button.restore {
    background-color: #34a853; /* Verde */
  }

.previewer .window-button.close {
    background-color: #ea4335; /* Rojo */
  }
  
.previewer .browser-address-bar {
    padding: 8px;
    background-color: #f9f9f9;
    border-bottom: 1px solid #ccc;
  }
  
.previewer  .address-input {
    width: 100%;
    padding: 4px 8px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #fff;
  }
  
.previewer .browser-body {
    padding: 16px;
    background-color: #fff;
    color: #333;
    border-radius: 0 0 8px 8px;
    min-height: 200px;
    text-align: center;
  }
  

