.profile-user-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;

}
.ẗable-profile-actions-header{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin: 30px 0px 30px 0;
}
.first-cell.rmwc-data-table__cell{
    vertical-align: bottom;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12) !important;
    padding-bottom: 0 !important;
}
.list-table-head{
    tr{
        th{
            background-color:rgba(41, 41, 41, 0.04);
        }
    }
}
.profile-container {
    .list-table-head {
        .rmwc-data-table__cell:first-of-type {
            padding: 15px 0;        
        }
    }
}
.rounded-button{
    border-radius: 50px;
    font-family: 'Space Mono';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
}
.users-actions{
    text-align: center;
    font-size: 18px;
    cursor: pointer;
    color: var(--primary-color);
    height: 100%;
    div{
        display: inline-block;
    }
}

.table-pagination{
    width: 70%;
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    justify-self: center;
    margin-left: auto;
    margin-right: auto;
    
    .table-pagination-element{
        width: 50%;
        display: flex;
        &.first{
            justify-content: flex-start;
        }
        &.second{
            justify-content: flex-end;
        }
        .table-pagination-link{
            cursor: pointer;
            font-family: 'Space Mono';
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 24px;


            color: #3487F0;
            &:hover{
                color: rgb(38, 94, 167);
            }
        }
    }
}