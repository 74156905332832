@font-face {
  font-family: 'studio';
  src: url('/fonts/fontello.woff');
}


 [class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "studio";
  font-style: normal;
  font-weight: normal;
  speak: none;
 
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  margin-top: .3em;
  /* opacity: .8; */
 
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
 
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
 
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;
 
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
 
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
 
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}
 
.icon-add:before { content: '\e800'; margin: 0; } /* '' */
.icon-add-circle:before { content: '\e801'; } /* '' */
.icon-arrow-down:before { content: '\e802'; } /* '' */
.icon-arrow-left:before { content: '\e803'; } /* '' */
.icon-arrow-right:before { content: '\e804'; } /* '' */
.icon-arrow-up:before { content: '\e805'; } /* '' */
.icon-campaings:before { content: '\e806'; } /* '' */
.icon-caret-down:before { content: '\e807'; } /* '' */
.icon-caret-left:before { content: '\e808'; } /* '' */
.icon-caret-right:before { content: '\e809'; } /* '' */
.icon-caret-small-down:before { content: '\e80a'; } /* '' */
.icon-caret-small-left:before { content: '\e80b'; } /* '' */
.icon-caret-small-right:before { content: '\e80c'; } /* '' */
.icon-caret-small-up:before { content: '\e80d'; } /* '' */
.icon-caret-up:before { content: '\e80e'; } /* '' */
.icon-checkbox:before { content: '\e80f'; } /* '' */
.icon-checkbox-blank:before { content: '\e810'; } /* '' */
.icon-checkbox-circle:before { content: '\e811'; } /* '' */
.icon-close:before { content: '\e812'; } /* '' */
.icon-close-circle:before { content: '\e813'; } /* '' */
.icon-close-circle-1:before { content: '\e814'; } /* '' */
.icon-copy:before { content: '\e815'; } /* '' */
.icon-delete-bin:before { content: '\e816'; } /* '' */
.icon-earth-line:before { content: '\e817'; } /* '' */
.icon-edit:before { content: '\e818'; } /* '' */
.icon-ellipsis:before { content: '\e819'; } /* '' */
.icon-error-warning:before { content: '\e81a'; } /* '' */
.icon-eye-line:before { content: '\e81b'; } /* '' */
.icon-filter:before { content: '\e81c'; } /* '' */
.icon-help:before { content: '\e81d'; } /* '' */
.icon-help-2:before { content: '\e81e'; } /* '' */
.icon-information:before { content: '\e81f'; } /* '' */
.icon-new:before { content: '\e820'; } /* '' */
.icon-pencil-ruler:before { content: '\e821'; } /* '' */
.icon-pending-timer:before { content: '\e822'; } /* '' */
.icon-_placeholder:before { content: '\e823'; } /* '' */
.icon-qr-code:before { content: '\e824'; } /* '' */
.icon-recent:before { content: '\e825'; } /* '' */
.icon-reports:before { content: '\e826'; } /* '' */
.icon-rocket:before { content: '\e827'; } /* '' */
.icon-scan:before { content: '\e828'; } /* '' */
.icon-search:before { content: '\e829'; } /* '' */
.icon-sort:before { content: '\e82a'; } /* '' */
.icon-sort-ascendent:before { content: '\e82b'; } /* '' */
.icon-sort-descendent:before { content: '\e82c'; } /* '' */
.icon-subtract:before { content: '\e82d'; } /* '' */
.icon-triangle-down:before { content: '\e82e'; } /* '' */
.icon-triangle-left:before { content: '\e82f'; } /* '' */
.icon-triangle-right:before { content: '\e830'; } /* '' */
.icon-triangle-small-down:before { content: '\e831'; } /* '' */
.icon-triangle-small-left:before { content: '\e832'; } /* '' */
.icon-triangle-small-right:before { content: '\e833'; } /* '' */
.icon-triangle-small-up:before { content: '\e834'; } /* '' */
.icon-triangle-up:before { content: '\e835'; } /* '' */
.icon-user:before { content: '\e836'; } /* '' */
.icon-view-grid-large:before { content: '\e837'; } /* '' */
.icon-view-grid-small:before { content: '\e838'; } /* '' */
.icon-view-list:before { content: '\e839'; } /* '' */
.icon-vip-crown:before { content: '\e83a'; } /* '' */
.icon-vip-diamond-fill:before { content: '\e83b'; } /* '' */
.icon-vip-diamond-line:before { content: '\e83c'; } /* '' */
.icon-check-line:before { content: '\e83d'; } /* '' */