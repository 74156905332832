pre {
    overflow-x: hidden !important;
    overflow-y: auto !important;
    height: 400px;
}
.modal-title{
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
}

.modal-btn-close{
    cursor: pointer;
}

button.sausage-button.fluid{
    width: auto !important;
}

.btn-modal-container{
    display: flex;
    justify-content: center;
    align-items: center;
}

.btn-copy{
    width: auto !important;
    background: #3487f0 !important;
    border-radius: 74px !important;
    color: white !important;
    font-family: Space Mono !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 16px !important;
}

.btn-copy:hover{
    background: rgba(52, 135, 240, 0.8) !important;
}

