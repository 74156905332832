.preview-container {
    height: 100%;
    max-width: 1073px;
    margin: auto;
    position: relative;
    border: 1px solid;
    border-color: rgba(41, 41, 41, 0.12);
    z-index: 0;
}

.preview {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
}