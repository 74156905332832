@import url('https://fonts.googleapis.com/css?family=Overpass&display=swap');
@import url('https://fonts.googleapis.com/css?family=Public+Sans&display=swap');
@import url('https://fonts.googleapis.com/css?family=Space+Mono&display=swap');
@import url('https://fonts.googleapis.com/css?family=Public+Sans&display=swap');

:root{
  --primary-color: #3487F0;
  --mdc-theme-primary: #3487F0;
  --mdc-theme-secondary: #3487F0;
  --mdc-theme-error: #EF0000 !important;
  --mdc-theme-background: #fff;
  --mdc-theme-surface: #fff;
  --mdc-theme-on-primary: rgba(255, 255, 255, 1);
  --mdc-theme-on-secondary: rgba(0, 0, 0, 0.87);
  --mdc-theme-on-surface: rgba(0, 0, 0, 0.87);
  --mdc-theme-on-error: #fff;
  --mdc-theme-text-primary-on-background: rgba(0, 0, 0, 0.87);
  --mdc-theme-text-secondary-on-background: rgba(0, 0, 0, 0.54);
  --mdc-theme-text-hint-on-background: rgba(0, 0, 0, 0.38);
  --mdc-theme-text-disabled-on-background: rgba(0, 0, 0, 0.38);
  --mdc-theme-text-icon-on-background: rgba(0, 0, 0, 0.38);
  --mdc-theme-text-primary-on-light: rgba(0, 0, 0, 0.87);
  --mdc-theme-text-secondary-on-light: rgba(0, 0, 0, 0.54);
  --mdc-theme-text-hint-on-light: rgba(0, 0, 0, 0.38);
  --mdc-theme-text-disabled-on-light: rgba(0, 0, 0, 0.38);
  --mdc-theme-text-icon-on-light: rgba(0, 0, 0, 0.38);
  --mdc-theme-text-primary-on-dark: white;
  --mdc-theme-text-secondary-on-dark: rgba(255, 255, 255, 0.7);
  --mdc-theme-text-hint-on-dark: rgba(255, 255, 255, 0.5);
  --mdc-theme-text-disabled-on-dark: rgba(255, 255, 255, 0.5);
  --mdc-theme-text-icon-on-dark: rgba(255, 255, 255, 0.5);


}

body {
  margin: 0;
  font-family: 'Public Sans', 'Poppins', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.body{
  font-family: 'Public Sans';
}

.sidebar{
  padding: 0px 8px 0px 0px;
}

.center{
  align-self: center;
}
.center-container{
  display: flex;
  justify-content: center;
  align-items: center;
}
.container-right{
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
a{
    text-decoration: none;
}
a:-webkit-any-link {
  color: inherit;
}
.cursor{
  cursor: pointer;
}
.cursor:hover{
  color: var(--primary-color);
}
.fullwidth{
  width: 100%;
}

