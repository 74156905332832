.subscription-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.toggleClass {
  margin-top: 10px;
  margin-left: 15px;
}

.paymentConditions {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Public Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 16px;
}

.paddingTables .rmwc-data-table__cell {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}

;

.toggleClass {
  background: #ff4672 !important;
}

.toggleClass.rs-btn-toggle-checked {
  background: #3487F0 !important;
}

.colorTitleSubs {
  font-family: Public Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 40px;
  /* identical to box height, or 133% */

  display: flex;
  align-items: center;
  text-align: center;

  /* Neutrals / Black */

  color: #292929;
}

.paymentCurrencyContainer {
  display: flex;
  justify-content: center;
  margin-top: 16px;
  margin-bottom: 16px;
}

.currencyP {
  font-family: Public Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
}

.subscription-container-tittle {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 64px;
  margin-top: 96px;

  h2 {
    display: flex;
    height: 50px;
    align-items: center;
    margin-right: 28px;
  }

  .ant-switch {
    background-color: rgba(202, 200, 200, 0.3);

    .ant-switch-inner {
      color: #ff4672;
    }
  }

  .ant-switch-checked {
    background-color: rgba(41, 41, 41, 0.30);
  }

  button {

    width: 200px;
    height: 50px;

    .ant-switch-inner {
      font-size: 16px;
    }

    .ant-switch-inner::after {
      top: 0;
      height: 50px;
      width: 50px;
      border-radius: 50px;
    }
  }

  .ant-switch::after {
    top: 0;
    height: 50px;
    width: 50px;
    border-radius: 50px;
  }
}

.pro-block-sub-table {
  padding-top: 9px;
  background-color: rgba(41, 41, 41, 0.05);

  button {
    margin: auto;
    margin-bottom: 10px;
    display: block;
  }
}

.rmwc-data-table__content {
  margin: auto;
}

.subs-table-title {
  font-family: 'Public Sans';
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */

  text-align: center;

  /* Neutrals / Black */

  color: #292929;
}


.subs-table-free {
  display: block;
  text-align: center;
  font-family: 'Public Sans';
  font-style: normal;
  font-weight: normal;
  font-size: 32px;
  line-height: 32px;


  /* Neutrals / Black */

  color: #292929;
}

.subs-table-pro {
  display: block;
  text-align: center;

  font-family: 'Public Sans';
  font-style: normal;
  font-weight: normal;
  font-size: 32px;
  line-height: 32px;
  /* identical to box height, or 100% */


  /* Neutrals / Black */

  color: #292929;
}

.subs-table-trial {
  display: block;
  text-align: center;

  font-family: 'Public Sans';
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height, or 133% */

  /* Neutrals / Black */

  color: #292929;
}

.subs-table-row {
  padding: 32px 0 32px 0;
}

.subs-table-pro td {
  font-family: Public Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
}

.subs-table-row-pro {
  background: rgba(41, 41, 41, 0.04);
}

.rmwc-data-table__cell:first-of-type {
  padding-top: 16px;
  padding-bottom: 16px;
}

.rmwc-data-table {
  border: none !important;
}

.checkmark {
  color: #0DD391;
}

.rmwc-data-table__content {
  width: 70%;
}

.subs-free-table {
  vertical-align: top !important;
  padding-top: 10px;
}

.rmwc-data-table__cell.borderless:first-child {
  border-bottom: none !important;
}

.StripeElement {
  width: 100%;
}

.subs-table-cancel {
  display: block;
  text-align: center;

  font-family: 'Public Sans';
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height, or 133% */


  /* Messages / Success */

  color: #0DD391;
  margin-bottom: 30px;
}

.zindexSelectP{
  z-index: 70 !important;
}

.error {
  font-family: Public Sans;
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 16px;
  text-align: left;
  text-align: initial;
  margin-top: 12px;
  color: #ed4331;
}

.containerAddressPostal {
  display: flex;
  text-align: left;
  margin-bottom: 8px;
  margin-top: 32px;
}

.subs-table-normal {
  display: block;
  text-align: center;

  font-family: 'Public Sans';
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height, or 133% */


  /* Messages / Success */

  color: #292929;
  margin-bottom: 30px;
}

.subs-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;

  .profile-card-container {
    align-self: center;

    .profile-item {
      border-bottom: none;
    }
  }
}

.subs-input {
  margin: 0px 10px 0 10px;

  &.card-number {
    width: 60%;
  }

  &.address {
    width: 80%;
  }
}

.subs-input-full {
  width: 100%
}

.profile-item {
  .subs-input {
    .mdc-text-field__input {
      color: #ff4672;

    }

    .mdc-notched-outline {
      .mdc-notched-outline__leading, .mdc-notched-outline__notch, .mdc-notched-outline__trailing {
        border-color: rgba(41, 41, 41, 0.12);
      }

      .mdc-notched-outline__notch {
        .mdc-floating-label {
          top: 21px !important;
          color: rgba(41, 41, 41, 0.72) !important;
        }

      }
    }

    &.mdc-text-field--focused {
      border: none;
    }
  }
}

.stripe {
  display: block;
  margin: auto;
  width: 110px;
}