.layout {
    padding: 16px 32px 0 32px;
}

.headerExpColor {
    background-color: #ffffff !important;
}

.headerExpColor .rs-icon {
    color: black;
}

.subTExp {
    text-align: center;
    padding: 10px;
}

.imageFaceTrack {
    background-image: url('/publish-app.svg');
}

.styleFaceFooter {
    cursor: pointer;

    /* Neutrals / White */

    background: #FFFFFF;
    border-radius: 12px;

    margin-top: 100px;
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    padding: 8px 16px 8px 8px;

}

.visibleOffline {
    visibility: visible;
}

.invisibleOffline {
    visibility: hidden;
}

.paddingTopFlexExp {
    padding-top: 30px;
}
.typeAd{
    position: absolute;
    top: 32px;
    left: 4px;
}

.heightInvisible {
    /* height: 485px; */
}

.bold {
    font-weight: bold;
    font-size: 20px;
}

.slick-prev {
    left: -15px !important;
}


.colorhoverFace {
    color: #3487F0;
    font-weight: bold;
    font-size: 20px;
}

.styleFaceFooterExpForm {
    display: flex;
    justify-content: center;
    margin-top: 50px;
}

.buttonFace.rs-btn-default.rs-btn-blue {
    color: white;
    background-color: #575757;
}

.boldText {
    font-weight: bold;
}

.containerIconInput {
    display: flex;
    width: 100%;
}

.grayHover {
    background: #FFFFFF;
    /* Neutrals / Alpha / 012 */

    border: 1px solid rgba(41, 41, 41, 0.12);
    border-left-color: transparent !important;
    box-sizing: border-box;
    border-radius: 2px !important;
}

.popoverBackground.rs-popover {
    background: black;
    color: white;
    font-family: Space Mono;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 12px;
    /* or 120% */

    display: flex;
    align-items: center;
    text-align: center;

}

.popoverBackground.rs-popover[class*='placement-right'] > .rs-popover-arrow::after {
    border-right-color: black;
    border-left-color: black;
}

.grayHoverInputSearch {
    background: #FFFFFF;
    /* Neutrals / Alpha / 012 */

    border: 1px solid rgba(41, 41, 41, 0.12) !important;
    box-sizing: border-box;
    border-radius: 2px !important;
}

.grayHoverInputSearch .rs-input-group-addon {
    border-bottom-left-radius: 0 !important;
    border-top-left-radius: 0 !important;
}


.searchIconContainer {
    display: flex;
    width: 35.84px;
    padding: 12px 12px;
    height: 40px;
    background-color: #f7f7fa;
    border-bottom-left-radius: 2px !important;;
    border-top-left-radius: 2px !important;;
    border-left: 1px solid rgba(41, 41, 41, 0.12) !important;
    border-top: 1px solid rgba(41, 41, 41, 0.12) !important;
    border-bottom: 1px solid rgba(41, 41, 41, 0.12) !important;
    border-right-color: transparent;
}

.textFormExperience {
    line-height: 16px;
    margin-bottom: 8px !important;
}

.textFormModelViewer {
    margin-top: 40px !important;
}

.newButton {
    text-transform: uppercase;
    font-weight: bold;
    float: right;
    /* top: -22px; */
    bottom: 40px;
    left: 10px;
}

.soonButton {
    float: right;
    /* top: -22px; */
    bottom: 40px;
    left: 10px;
    background: #E7F1FD !important;
    /* Buttons / Containers / Studio rest */

    box-shadow: 0px 2px 4px rgba(35, 92, 163, 0.24);
    border-radius: 4px;
    color: #3487F0 !important;
    font-weight: bold !important;

}

.headerExpColor.rs-navbar.rs-navbar-inverse .rs-navbar-nav .rs-nav-item > .rs-nav-item-content:hover, .rs-navbar.rs-navbar-inverse .rs-navbar-nav .rs-dropdown .rs-dropdown-toggle:hover {
    background-color: #ffffff !important;
}

.headerExpColor.rs-navbar.rs-navbar-inverse .rs-navbar-nav .rs-nav-item > .rs-nav-item-content:active, .rs-navbar.rs-navbar-inverse .rs-navbar-nav .rs-dropdown .rs-dropdown-toggle:active {
    background-color: #ffffff !important;
}

.headerExpColor.rs-navbar.rs-navbar-inverse .rs-navbar-nav .rs-nav-item > .rs-nav-item-content:hover, .rs-navbar.rs-navbar-inverse .rs-navbar-nav .rs-dropdown .rs-dropdown-toggle:hover, .rs-navbar.rs-navbar-inverse .rs-navbar-nav .rs-nav-item > .rs-nav-item-content:focus, .rs-navbar.rs-navbar-inverse .rs-navbar-nav .rs-dropdown .rs-dropdown-toggle:focus {
    background-color: #ffffff !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0px 1000px #ffffff inset !important;
}

.activeExp {
    opacity: 1;
}

.inactiveExp {
    opacity: 0.5;
}

.inactiveAndDisabledExp {
    opacity: 0.5;
    pointer-events: none;
}

.slider .slick-slide {
    width: 266px
}

.svgArrowPrev {
    margin-left: 19px;
    margin-top: 12px;
}

.marginLeftImage {
    margin-right: 8px;
}

/*new label*/
.labelInput {
    font-family: Public Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 16px;
    color: #292929;
    text-align: initial;
}
.profile-element .labelInput {
    margin-bottom: 8px;
    margin-top: 32px;
}

@media (max-width: 1300px) {
    .filters-actions .filter-action-container {
        width: 100%;
    }

    .filters-actions .filter-action-container .filters-action {
        margin: 0px;
    }
}

@media (min-width: 800px) {
    .filters-actions .filter-action-container {
        width: 100%;
    }

    .filters-actions .filter-action-container .filters-action {
        margin: 0px;
    }
}

.divNextArrow {
    position: absolute;
    width: 48px;
    height: 40px;
    left: 98%;
    top: 101px;

    /* Neutrals / White */

    background: #FFFFFF;
    /* Elevation / (Low) Card rest */

    box-shadow: 0px 0px 2px rgba(41, 41, 41, 0.12), 0px 4px 8px rgba(41, 41, 41, 0.08);
    border-radius: 24px;
}

.margin-card {
    margin-left: 3px
}

.margin-card .mdc-card__actions {
    padding: 0px;
}

.slick-disabled {
    display: none;
}

.divNextArrow:before {
    display: none;
}

.divPrevArrow:before {
    display: none;
}

.slick-next:focus, .slick-next:hover, .slick-prev:focus, .slick-prev:hover {
    background: #FFFFFF;
}

.divPrevArrow {
    cursor: pointer;
    position: absolute;
    width: 48px;
    height: 40px;
    left: -17px;
    top: 101px;
    z-index: 1;

    /* Neutrals / White */

    background: #FFFFFF;
    /* Elevation / (Low) Card rest */

    box-shadow: 0px 0px 2px rgba(41, 41, 41, 0.12), 0px 4px 8px rgba(41, 41, 41, 0.08);
    border-radius: 24px;
}

.marginRef {
    margin-left: 2px;
    margin-right: 4px;
    color: #1675e0 !important;
    text-decoration: underline;
}

.widthVariable {
    width: 50%;
}

.widthComplete {
    width: 100%;
}

.Layout {
    margin-right: 0;
    margin-left: 0;
}

.hrefNoUnder a:not(.active):hover, a:hover, a:focus, a:active {
    text-decoration: none !important;
}

.elipsisText {
    margin-top: 2px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    /* border: 1px solid #000000; */
}

.marginTitleFooterFSuscp {
    margin-bottom: 15px;
}

.sidebar {
    position: fixed !important;
    z-index: 20;
    top: 56px;
    left: 8px;
    bottom: 8px;
    height: calc(100vh - 67px);
    padding-bottom: 0;
    background: #fefefe;
    box-shadow: 0px 8px 32px rgba(41, 41, 41, 0.08);
    border-radius: 1rem;
}

.titleFooterSubs {
    font-family: Public Sans;
    font-size: 11px;
}

.styleText {
    font-family: Space Mono;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
}

.formButton {
    margin-top: 20px;
}

/*div.sidebar:before {
    content: "";
    display: block;
    background: #FFFFFF;
    width: 100%;
    height: 20px;
    float: left;
    margin: 0;
    position: relative;
    top: -20px;
    padding: 0px 0px 0px 8px;
    left: 0;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
}*/

.content {
    padding-right: 40px;
    padding-left: 40px;
}

.Flex.content {
    width: 80vw;
    padding-top: 90px;
    padding-left: 20rem;
    overflow-x: hidden;
}

/* hardcodeo lvl 100, cuando haya UI refactorear */
.list-container{
    height: 280px!important;
}

.slick-list{
    padding: 30px 30px 0px 30px!important;
    position: relative;
    transform: translateX(-30px) translateY(-30px)!important;
}

/* end hardcodeo */

.Flex.content.collapsed {
    padding-left: 120px;
}

.Flex.content-full {
    padding-left: 40px;
}

.placeHolderSearch::placeholder {
    font-family: Public Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    /* identical to box height, or 114% */
    color: rgba(101, 101, 101, 1) !important;
    display: flex;
    align-items: center;
    margin-bottom: 20px !important;
    margin-left: 10px;
}