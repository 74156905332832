.table{
    width: 100%;
    margin-top: 1rem;
    border:none
}
.table-row{
    display: flex;
    justify-content: space-around;
    align-items: center;
}
.table-row-left{
    display: flex;
    width: 70%;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.table-row-rigth{
    display: flex;
    flex-direction: row;
    width: 30%;
    align-items: center;
    justify-content: space-around;
}

.table-row-title{
    display: flex;
    width: 33%;
    align-items: center;
    font-size: 11px;
    font-weight: 700;
    color: rgba(0, 0, 0, 0.48);
}
.table-row-title-rigth{
    display: flex;
    width: 33%;
    align-items: center;
    font-size: 11px;
    justify-content: center;
    font-weight: 700;
    color: rgba(0, 0, 0, 0.48);
}