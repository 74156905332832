.tabs-profile-container {
  margin-left: 32px;
  margin-right: 32px;
}

.profile-container {
  display: flex;
  justify-content: center;
}

.marginButtonPro {
  margin-bottom: 10px;
}

.pinkC {
  background-color: #ddd !important;
}

.blueC {
  background-color: red !important;
}

.pro-buttonBlue {
  transition: 0.3s;
  border-radius: 50px;
  background-color: #3487F0 !important;
  color: #fff;
  height: 50px;
  width: 230px;

  &:disabled {
    background-color: #3487F0 !important;

  }
}


.pro-button {
  transition: 0.3s;
  border-radius: 50px;
  background-color: #ff4672 !important;
  color: #fff;
  height: 50px;
  width: 230px;

  &:disabled {
    background-color: #ff4672 !important;

  }
}


.ẗable-profile-actions-header {
  .mdc-text-field {
    width: 500px;
  }
}

.ico-done {
  color: #0DD391;
}

.ico-close {
  color: #EF0000;
}

.Profile__back-section {
  display: flex;
  margin-top: 16px;
  margin-left: 16px;
  margin-bottom: 40px;
  font-family: 'Space Mono';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.05em;
  color: #3487F0;

  .back-button {
    font-size: 16px;
  }
}