.titleMargin {
  font-family: Public Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
}

.list-header {
  height: 4rem;
  display: flex;
  align-items: center;

  .title {
    font-family: "Public Sans";
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
  }

  .action {
    margin-left: 10px;
    font-family: "Space Mono";
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    color: var(--primary-color);
  }
}

.action {
  cursor: pointer;
  margin-left: 10px;
  font-family: "Space Mono";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: var(--primary-color);
  margin-top: 1.5px;
}

.list-container {
  margin: 29px 0 6px 0;
  display: flex;
  flex-direction: column !important;

  .list-container-header {
    margin: 15px 0 9px 0;
    display: flex;
    flex-direction: row;

    width: 100%;

    .header-actions {
      display: flex;
      flex-direction: row;
      margin-left: auto;

      .actions {
        display: flex;
        flex-direction: row;
        color: #292929;
        align-items: center;

        .value {
          font-family: Public Sans;
          font-style: normal;
          font-weight: normal;
          margin-right: 18px;
          font-size: 12px;
          color: #292929;
        }

        .quantity {
          font-family: "Public Sans";
          font-style: normal;
          font-weight: normal;
          margin-right: 16px;
          font-size: 14px;
          color: #292929;
          width: 115px;
          text-align: center;
        }
      }
    }
  }
}

.experiences-container {
  overflow-x: auto;
  padding-bottom: 1rem;
  display: grid;
  grid-gap: 6px;
  grid-template-columns: repeat(100, 270px);
  /* grid-template-rows: minmax(150px, 1fr); */
  /* grid-auto-flow: column; */
  grid-auto-columns: calc(50% - var(--gutter) * 2);
  /* padding: 0 20px; */

  /* grid-gap: 10px; */
  /* grid-template-columns: repeat(6, calc(50% - 40px)); */
  /* grid-template-rows: minmax(150px, 1fr); */
  /* width: 100%; */
}

.menu-item {
  &.disable {
    opacity: 0.5;
    cursor: default !important;

    &:hover {
      background: none;
    }
  }
}